import React from "react";

interface ILogProps {
    value: any
}

const Log: React.FC<ILogProps> = ({value}) => {
    console.log("XXX log", value)
    return null;
}
export default Log;