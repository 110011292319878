import ValidationHttpError from "./errors/BadRequest/ValidationHttpError";
import UserNotFoundHttpError from "./errors/BadRequest/UserNotFoundHttpError";
import TokenNotFoundHttpError from "./errors/BadRequest/TokenNotFoundHttpError";
import TokenExpiredHttpError from "./errors/BadRequest/TokenExpiredHttpError";
import NotFoundHttpError from "./errors/BadRequest/NotFoundHttpError";

export const createError = (errorId: number, response: any) => {
    switch (errorId) {
        case 1008:
            return new UserNotFoundHttpError(response);
        case 1009:
            return new TokenNotFoundHttpError(response);
        case 1010:
            return new ValidationHttpError(response);
        case 1011:
            return new TokenExpiredHttpError(response);
        case 1012:
            return new NotFoundHttpError(response);
    }
};
