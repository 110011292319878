import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

export interface IFooterContext {
    footerContent?: string;
    setFooterContent: Dispatch<SetStateAction<string | undefined>>;
}

export const FooterContext = createContext<IFooterContext>({
    setFooterContent: () => null,
});

interface IFooterProviderProps {}

const FooterProvider: React.FC<PropsWithChildren<IFooterProviderProps>> = ({ children }) => {
    const [footerContent, setFooterContent] = useState<string>();

    const value = { footerContent, setFooterContent };

    return (
        <FooterContext.Provider value={value}>
            {children}
            {footerContent}
        </FooterContext.Provider>
    );
};

export default FooterProvider;
