import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            position: "relative",
            "& .MuiDialogContent-root": {
                padding: theme.spacing(1),
                minHeight: "10rem",
                [theme.breakpoints.up("md")]: {
                    padding: theme.spacing(2),
                },
            },
            "& .MuiDialogActions-root": {
                padding: theme.spacing(1),
                "& button": {
                    marginLeft: `${theme.spacing(1)} !important`,
                },
                [theme.breakpoints.up("md")]: {
                    padding: theme.spacing(2),
                    "& button": {
                        marginLeft: `${theme.spacing(2)} !important`,
                    },
                },
            },
        },
        dialogTitleRoot: {
            padding: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
            },
        },
        closeButton: {
            position: "absolute",
            color: theme.palette.grey[500],
            top: theme.spacing(1),
            right: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                top: theme.spacing(2),
                right: theme.spacing(2),
            },
        },
    })
);

export default useStyles;
