import { Notifier } from "@iolabs/notifier";
import { IonRouterOutlet } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Route, useRouteMatch } from "react-router";
import MinimalisticPage from "../../pages/MinimalisticPage/MinimalisticPage";
import "../../theme/variables.css";
import useStyles from "./styles";

interface IProps {
    children?: any;
}

const PublicApp: React.FC<IProps> = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    return (
        <>
            <SnackbarProvider
                maxSnack={3}
                dense
                preventDuplicate
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
                    variantWarning: classes.warning,
                    variantInfo: classes.info,
                }}
            >
                <IonRouterOutlet>
                    <Route path={`${match.path}/minimal`} component={MinimalisticPage} />
                </IonRouterOutlet>
                <Notifier />
            </SnackbarProvider>
        </>
    );
};

export default PublicApp;
