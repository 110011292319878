import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useKeycloak } from "react-keycloak";
import { Notifier } from "@iolabs/notifier";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        success: {
            backgroundColor: theme.palette.secondary.main,
        },
        error: {
            backgroundColor: theme.palette.error.main,
        },
        warning: {
            backgroundColor: theme.palette.error.main,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
    })
);

interface IProps {
    children?: any;
    globalCss: React.ReactNode
}


const App: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();
    const { keycloak } = useKeycloak();
    const { children, globalCss } = props;

    return (
        <SnackbarProvider
            maxSnack={3}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
            }}
        >
            {keycloak.authenticated ? (
                <>
                    {globalCss}
                    {children}
                    <Notifier />
                </>
            ) : (
                <></>
            )}
        </SnackbarProvider>
    );
};

export default App;
