import React, {useCallback, useState} from "react";
import CrudModule, {ItemField, OnSubmitDone} from "../../../Crudder/CrudModule";
import {useKeycloak} from "@react-keycloak/web";
import {SystemVariableRequest, SystemVariableResponse} from "../../../Api/data/systemVariable/types";
import {
    addSystemVariable,
    deleteSystemVariable,
    getAllSystemVariables,
    getSystemVariableDetails,
    updateSystemVariable
} from "../../../Api/data/systemVariable/client";

const fields : ItemField<SystemVariableRequest>[] = [
    {
        name: "systemVariableID",
        title: "Identifikátor",
        description: "Identifikátor",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "code",
        title: "Kód",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "value",
        title: "Hodnota",
        columnDef: {
            flex: 3
        },
        formFieldDef: {
            multiline: true
        }
    },
];


const SystemVariablesModule: React.FC = () => {

    const [ data, setData ] = useState<SystemVariableResponse[]>([]);


    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<SystemVariableResponse[]>((resolve, reject) => {
            getAllSystemVariables().then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<OnSubmitDone>((resolve, reject) => {
            if (formValues["systemVariableID"]) {
                // update
                // @ts-ignore
                updateSystemVariable(formValues["systemVariableID"], formValues as SystemVariableRequest).then((response) => {
                    resolve({id: response.systemVariableID});
                })
            }
            else {
                // create
                // @ts-ignore
                addSystemVariable(formValues).then((response) => {
                    resolve({id: response.systemVariableID, inserted: true});
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteSystemVariable(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<any>((resolve, reject) => {
            getSystemVariableDetails(id).then((data) => {
                resolve(data)
            })
        });
    }, [data]);


    return ( keycloakInitialized && keycloak?.token ? (
            <CrudModule<SystemVariableResponse, SystemVariableRequest>
                name={"Proměnné systému"}
                list={{
                    title: "Přehled proměnných systému",
                    getRowID: (entity) => entity.systemVariableID,
                    disableRemove: true,
                    disableAdd: true
                }}
                detail={{
                    title: "Detail proměnné systému",
                }}
                itemFields={fields as any}
                data={fetchData}
                getDetail={getDetail}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
            />
    ) : null

    );
};

export default SystemVariablesModule;