import { IModule } from "redux-dynamic-modules";
import middlewares from "./middleware";
import { IKeycloakModuleState } from "./reducer";
import { reducer } from "./reducer";

export const KeycloakModule: IModule<IKeycloakModuleState> = {
    id: "keycloak-module",
    reducerMap: {
        keycloakState: reducer,
    },
    middlewares: middlewares,
};
