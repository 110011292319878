import React from "react";
import useStyles from "./styles";
import StyledTextField from "./StyledTextField";
import {ItemField} from "../../CrudModule";
import {FormApi} from "final-form";


export interface ICrudDetailAutoFields<DT> {
    form: FormApi<DT>,
    itemFields: ItemField<DT>[],
    loading?: boolean,
    submitting?: boolean,
}

const CrudDetailAutoFields = <DT,>({ form, itemFields, submitting, loading} : ICrudDetailAutoFields<DT> ) => {
    const classes = useStyles();

    console.log("xxx rendering autofields");

    console.log("XXX counter itemFields", itemFields);

    const getField = (field: ItemField<DT>, submitting?: boolean) => (
        <StyledTextField
            key={`field-${field.name}`}
            name={field.name}
            label={field.title}
            type={field.formFieldDef?.type}
            loading={loading}
            submitting={submitting}
            multiline={field.formFieldDef?.multiline}
        />
    )

    return (
        <>
            {itemFields && itemFields
                .filter((field) => !field.formFieldDef?.ignore)
                .map((field) => (
                    field.formFieldDef?.render ? field.formFieldDef?.render(field, classes, form, submitting || loading) : (
                        <>
                            {getField(field, submitting)}
                        </>
                    )

                ))}
        </>
    );
};
export default CrudDetailAutoFields;