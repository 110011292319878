import React from "react";
import {Grid, MenuItem, Paper, Typography} from "@material-ui/core";
import {IFormTemplate} from "../../../../Crudder/components/CrudDetail/CrudDetailForm";
import useStyles from "./styles";
import {useForm, useFormState} from "react-final-form";
import {OrderResponse} from "../../../../Api/data/order/types";
import CrudDetailAutoFields from "../../../../Crudder/components/CrudDetail/CrudDetailAutoFields";
import CompanyContactsFormPart from "./CompanyContactsFormPart";
import InputSelect from "../../../../Crudder/components/InputSelect";
import {getAllCurrencies} from "../../../../Api/data/currency/client";
import {CurrencyResponse} from "../../../../Api/data/currency/types";

interface IProcessingFileProps {
    name: string,
    progress: number,
}

const CompanyForm: React.FC<IFormTemplate<OrderResponse>> = ({ data, itemFields}) => {
    const classes = useStyles();

    const form = useForm();
    const {submitting} = useFormState({
        subscription: {
            submitting: true,
        }
    });

    return (
        <>

            <Paper className={classes.section}>
                <Typography variant={"caption"}>Základní údaje</Typography>
                <CrudDetailAutoFields<OrderResponse> form={form as any} itemFields={itemFields} submitting={submitting} />
                <InputSelect
                    field={{name: "currencyID", title: "Preferovaná měna"}}
                    // className={classes.companyInput}
                    itemsGetter={async (search) => {
                        const currencies: CurrencyResponse[] = await getAllCurrencies();
                        return currencies.map(c => (
                            <MenuItem key={`mi-c-${c.currencyID}`} value={c.currencyID}>{c.alpha3Code}</MenuItem>
                        ))
                    }}
                />
            </Paper>

            <Paper className={classes.section}>
                <Typography variant={"caption"}>Doprava</Typography>
                <CrudDetailAutoFields form={form} itemFields={[
                    {
                        name: "distanceKm",
                        title: "Vzdálenost v km",
                        description: "Vzdálenost v kilometrech",
                        formFieldDef: {
                            type: "number"
                        }
                    },
                    {
                        name: "distanceH",
                        title: "Vzdálenost v h",
                        description: "Vzdálenost v hodinách",
                        formFieldDef: {
                            type: "number"
                        }
                    },
                ]} submitting={submitting} />
            </Paper>



            <Paper className={classes.section}>
                <Typography variant={"caption"}>Adresa</Typography>
                <CrudDetailAutoFields form={form} itemFields={[
                    {
                        name: "address.line1",
                        title: "Řádek 1",
                        description: "Řádek 1",
                    },
                    {
                        name: "address.line2",
                        title: "Řádek 2",
                        description: "Řádek 2",
                    },
                    {
                        name: "address.city",
                        title: "Město",
                        description: "Město",
                    },
                    {
                        name: "address.country",
                        title: "Země",
                        description: "Země",
                    },
                    {
                        name: "address.zipCode",
                        title: "PSČ",
                        description: "PSČ",
                    }
                ]} submitting={submitting} />
            </Paper>

            <Paper className={classes.section}>
                <Typography variant={"caption"}>Konktakty</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <CompanyContactsFormPart field={{name: "contactPersons", title: "Konktaktní osoby"}} form={form} />
                    </Grid>
                </Grid>
            </Paper>

        </>
    );
}
export default CompanyForm;