import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {SizeMe} from 'react-sizeme'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery
} from "@material-ui/core";
import {OrderRequest, OrderResponse, OrderTempPdf} from "../../../../Api/data/order/types";
import {generateOrder, updateOrder} from "../../../../Api/data/order/client";
import {useTheme} from "@material-ui/core/styles";
import useStyles from "./styles";
import {Close} from "@material-ui/icons";

interface IOrderPreviewProps {
    order: OrderResponse;
    onClose?: (filesUpdated?: boolean, fileToSend?: number) => void;
}

const OrderPreview: React.FC<IOrderPreviewProps> = ({ order, onClose}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [pdfString, setPdfString] = useState<string>();
    const [ tempPdf, setTempPdf ] = useState<OrderTempPdf>();
    const [ numPages, setNumPages ] = useState<number>();
    const [ loading, setLoading ] = useState<boolean>(true);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleClose = () => {
        if(onClose) {
            onClose();
        }
    }

    const confirm = (send?: boolean) => {
        updateOrder(order.orderID, {
            ...order,
            files: [
                tempPdf?.id
            ]
        } as OrderRequest).then((response) => {
            if(onClose) {
                const addedFile = response.orderFiles?.find(of => of.fileID == tempPdf?.id);
                onClose(true, send ? addedFile?.orderFileID : undefined);
            }
        })
    }

    const handleConfirm = () => {
        confirm();
    }

    const handleConfirmAndSend = () => {
        confirm(true);
    }

    useEffect(() => {
        if (order.orderID) {
            setLoading(true);
            generateOrder(order.orderID).then(r => {
                setTempPdf(r);
            });
        }
        else {
            console.error("No order loaded")
        }
    }, [order]);


    useEffect(() => {
        if (tempPdf) {
            let reader = new FileReader();
            reader.readAsDataURL(tempPdf.data);
            reader.onloadend = () => {
                const base64String = reader.result as string;
                setLoading(false);
                setPdfString(base64String.substr(base64String.indexOf(',') + 1));
            };
        }
        else {
            setLoading(false);
            setPdfString(undefined);
        }

    }, [tempPdf]);


    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullScreen={fullScreen}
                maxWidth={false}
            >
                <DialogTitle id="customized-dialog-title">
                    Náhled nabídky
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className={classes.orderPreviewDialogContent}>
                    { loading ? (
                        <CircularProgress />
                    ) : (
                        <SizeMe>
                            {({size}) => (
                                <Document
                                    file={`data:application/pdf;base64,${pdfString}`}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={1} width={size.width ? size.width : 1} />
                                </Document>
                            )}
                        </SizeMe>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleConfirmAndSend} color="primary" variant={"contained"}>
                        Přiřadit a odeslat
                    </Button>
                    <Button onClick={handleConfirm} color="primary" variant={"contained"}>
                        Přiřadit
                    </Button>
                    <Button onClick={handleClose} color="secondary" variant={"contained"}>
                        Zahodit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default OrderPreview;