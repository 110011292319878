import ToolButton, { IToolButtonProps } from "./ToolButton";
import React from "react";
import { Box } from "@material-ui/core";

interface IPanelMenuProps {
    items: IToolButtonProps[];
}

const PanelMenu: React.FC<IPanelMenuProps> = ({ items  }) => {
    return <Box>
        {items.map(item => (
            <ToolButton key={item.link} link={item.link} title={item.title} />
        ))}
    </Box>
}

export default PanelMenu;