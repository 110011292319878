import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import transMessages from "./messages";

interface IConfirmDialogProps {
    open: boolean;
    handleClose: (event: any) => void;
    handleConfirm: (event: any) => void;
    messages?: {
        dialogTitle?: string;
        confirmMessage?: any;
        closeButton?: string;
        confirmButton?: string;
    };
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = ({ open, handleClose, handleConfirm, messages }) => {
    const classes = useStyles();

    const intl = useIntl();

    const dialogTitle = messages?.dialogTitle ? messages.dialogTitle : intl.formatMessage({ ...transMessages.dialogTitle });
    const confirmMessage = messages?.confirmMessage ? messages.confirmMessage : intl.formatMessage({ ...transMessages.confirmMessage });
    const closeButtonText = messages?.closeButton ? messages.closeButton : intl.formatMessage({ ...transMessages.closeButton });
    const confirmButtonText = messages?.confirmButton ? messages.confirmButton : intl.formatMessage({ ...transMessages.confirmButton });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            maxWidth="sm"
            fullWidth
            className={classes.root}
            scroll="paper"
        >
            <DialogTitle disableTypography id="confirm-dialog-title" classes={{ root: classes.dialogTitleRoot }}>
                <Typography variant="h3">{dialogTitle}</Typography>
            </DialogTitle>
            <DialogContent dividers classes={{ root: classes.dialogContentRoot }}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                <Typography gutterBottom variant="body2">
                    {confirmMessage}
                </Typography>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                <Button variant="contained" color="default" size="small" onClick={handleClose}>
                    {closeButtonText}
                </Button>
                <Button
                    autoFocus
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleConfirm}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
