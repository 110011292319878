import { Box, createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import useFooterContent from "../../components/FooterProvider/useFooterContent";
import { dateIsAfter } from "../../utils/Date";
import clsx from "clsx";
import { template } from "../../theme/Theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            padding: theme.spacing(1),
            height: `calc(100% - 60px - ${theme.spacing(1)})`,
            background: template.bodyBgColor,
            [theme.breakpoints.up("md")]: {
                height: `calc(100% - 30px - ${theme.spacing(1)})`,
            },
        },
        noPadding: {
            padding: 0,
            // height: `calc(100% - 60px + ${theme.spacing(1)})`,
            height: `calc(100% - 30px + ${theme.spacing(1)})`,
            [theme.breakpoints.up("md")]: {
                height: `calc(100% - 30px + ${theme.spacing(1)})`,
            },
        }
    })
);

interface IPageProps extends React.HTMLAttributes<HTMLElement> {
    noPadding?: boolean;
}

const Page: React.FC<IPageProps> = (props: IPageProps) => {
    const { children, noPadding } = props;

    const classes = useStyles();
    const { setFooterContent } = useFooterContent();

    return <Box className={clsx(classes.root, {[classes.noPadding]: noPadding}) }>{children}</Box>;
};

export default Page;
