import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {
    OrderFileResponse,
    OrderRequest,
    OrderResponse, OrderTempPdf, SendMailDto
} from "./types";

const api = new ApiClient("/Order", config.api.baseUrl);

/**
 * Get all orders basic information.
 */
export const getAllOrders = (): Promise<OrderResponse[]> => {
    return api
        .get(`/List`)
        .then((response: AxiosResponse<OrderResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Get order basic information.
 * @param orderId
 */
export const getOrderDetails = (orderId: number): Promise<OrderResponse> => {
    return api
        .get(`/${orderId}`)
        .then((response: AxiosResponse<OrderResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
/**
 * Get order default data.
 */
export const getInitialData = (): Promise<OrderResponse> => {
    return api
        .get(`/Defaults`)
        .then((response: AxiosResponse<OrderResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Generate printable order
 * @param orderId
 */
export const generateOrder = (orderId: number): Promise<OrderTempPdf> => {
    return api
        .get(`/${orderId}/Generate`, {
            responseType: "blob",
        })
        .then((response: AxiosResponse<Blob>) => {
            return Promise.resolve({
                data: response.data,
                id: parseInt(response.headers["x-order-file-id"])
            });
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Send email regarding order
 * @param orderId
 */
export const sendEmail = (orderId: number, data: SendMailDto): Promise<void> => {
    return api
        .post(`/${orderId}/SendEmail`, data)
        .then((response: AxiosResponse<Blob>) => {
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Get send email default data.
 */
export const getSendEmailInitialData = (orderId: number): Promise<SendMailDto> => {
    return api
        .get(`/${orderId}/SendEmailDefaults`)
        .then((response: AxiosResponse<SendMailDto>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Update order information.
 * @param orderID
 * @param data
 */
export const updateOrder = (orderId: number, data: OrderRequest): Promise<OrderResponse> => {
    return api
        .post(`/${orderId}`, data)
        .then((response: AxiosResponse<OrderResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Add new order.
 * @param token
 * @param data
 */
export const addOrder = (data: OrderRequest): Promise<OrderResponse> => {
    return api
        .post(`/New`, data)
        .then((response: AxiosResponse<OrderResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Delete order.
 * @param token
 * @param orderId
 */
export const deleteOrder = (orderId: number): Promise<void> => {
    return api
        .delete(`/${orderId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Get all orders attachments.
 * @param token
 * @param code
 */
export const getAllOrderAttachments = (orderId: number): Promise<OrderFileResponse[]> => {
    return api
        .get(`/${orderId}/Files`)
        .then((response: AxiosResponse<OrderFileResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Delete order attachment.
 * @param token
 * @param code
 */
export const deleteOrderAttachment = (orderId: number, attachmentId: number): Promise<void> => {
    return api
        .delete(`/${orderId}/Attachment/${attachmentId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};