import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(2),
        },
        form: {
            width: "100%",
        },
        submitting: {
        },
        textField: {
            // "& input": {
            //     padding: "10px 10px !important",
            //     fontSize: theme.typography.pxToRem(11),
            // },
            // "& .MuiAutocomplete-input": {
            //     padding: "10px 10px !important",
            //     fontSize: theme.typography.pxToRem(11),
            // },
            // "& .MuiFormLabel-root": {
            //     color: theme.palette.primary.main,
            //     fontSize: theme.typography.pxToRem(13),
            // },
            // "& .MuiSelect-outlined.MuiSelect-outlined": {
            //     padding: "0.39rem 10px !important",
            //     fontSize: theme.typography.pxToRem(11),
            // },
            // "& .MuiOutlinedInput-multiline": {
            //     padding: "10px 10px !important",
            //     fontSize: theme.typography.pxToRem(11),
            // },
            // "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']": {
            //     padding: 0,
            // },
            // "& fieldset > legend": {
            //     maxWidth: 0,
            // },
            // "& label": {
            //     paddingLeft: "5px",
            //     paddingRight: "5px",
            //     background: theme.palette.common.white,
            // },
        },
        checkboxes: {
            "& legend": {
                display: "none",
            },
            "& .MuiInputBase-input": {
                height: "auto",
            },
            "& .MuiFormControlLabel-label": {
                fontSize: theme.typography.pxToRem(11),
            }
        },
        fieldset: {
            fontWeight: 400,
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.grey[400],
            },
        },
        progress: {
            color: theme.palette.primary.main,
            background: theme.palette.common.white,
        },
        chips: {
            "&  .MuiChip-root": {
                fontSize: theme.typography.pxToRem(11),
                height: "auto",
                marginRight: theme.spacing(0.5),
            }
        },
        panel: {
            alignItems: "center",
            backgroundColor: theme.palette.primary[200],
            color: theme.palette.text.primary,
            display: "flex",
            flexFlow: "row nowrap",
            minHeight: `40px`,
            height: `40px`,
            justifyContent: "space-between",
            padding: theme.spacing(0, 2),
            overflow: "hidden"
        },
        submitError: {
            marginTop: theme.spacing(1),
        },
        section: {
            padding: theme.spacing(2),
            margin: theme.spacing(2),
            "& .MuiTypography-caption": {
                margin: theme.spacing(-1),
                marginBottom: theme.spacing(1),
                fontSize: theme.typography.pxToRem(11),
                textTransform: 'uppercase',
                display: 'block'
            },
        },
        sectionAttachments: {
            marginBottom: 0,
        },
        formHtml: {
            height: "calc(100% - 25px)",
        },
        formWrapper: {
            height: "100%",
        },
        formContent: {
            flex: 1,
            overflowY: "auto",
        },
        formButtons: {
            height: "32px",
            margin: theme.spacing(2),

        },
        stayBtn: {
            "& .MuiFormControl-marginDense": {
                margin: 0,
            }
        }
    })
);

export default useStyles;
