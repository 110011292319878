import React, {
    PropsWithChildren,
    useEffect,
    useState
} from 'react';
import { IntlProvider } from 'react-intl';
import { LanguageProviderContext } from './context';

export interface IProps {
    messages: {[key: string]: Record<string, string>};
    defaultLocale: string;
}

export const getBrowserLanguage = () => {
    return navigator.language.split(/[-_]/)[0];
};

const LanguageProvider: React.FC<IProps> = (props: PropsWithChildren<IProps>) => {
    const { children, messages, defaultLocale } = props;
    const [ languages, setLanguages ] = useState<string[]>([]);
    const localStorageKey = 'language-provider_currentLanguage';

    useEffect(() => {
        setLanguages(Object.keys(messages));
    }, [messages]);

    let language: string;

    /**
     * Load current language from localStorage
     * - if not in localStorage se by browser
     *
     * Detect language from browser
     * - if missing translations for specific language, default message will be used as fallback
     */
    const localStorageLang = localStorage.getItem(localStorageKey);
    if(localStorageLang && languages.includes(localStorageLang)) {
        language = localStorageLang;
    }
    else {
        language = getBrowserLanguage();
    }

    /**
     * If language dont exists in actual languages - set default language
     */
    if(languages && languages.length && !languages.includes(language)) {
        language = defaultLocale;
    }

    /**
     * Set current language and save it to localStorage
     */
    const [ currentLanguage, setCurrentLanguage ] = useState<string>(language);
    useEffect(() => {
        localStorage.setItem(localStorageKey, currentLanguage);
    }, [currentLanguage]);

    useEffect(() => {
        setCurrentLanguage(language);
    }, [languages]);

    /**
     * Set context for external usage
     */
    const contextValue = { languages, setLanguages, currentLanguage, setCurrentLanguage };

    return (
        <LanguageProviderContext.Provider value={contextValue}>
            <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]} defaultLocale={currentLanguage}>
                {children}
            </IntlProvider>
        </LanguageProviderContext.Provider>
    );
};

export default LanguageProvider;
