import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: "10px",
            marginBottom: "-12px",
            paddingTop: "4px",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            "&.active": {
                backgroundColor: theme.palette.grey[50],
                color: "black",
                "& .MuiTypography-root": {
                    fontWeight: 400,
                },
            },
        },
    })
);

export default useStyles;
