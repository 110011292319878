import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {SystemVariableRequest, SystemVariableResponse} from "./types";
import {OrderResponse} from "../order/types";

const api = new ApiClient("/SystemVariable", config.api.baseUrl);

/**
 * Get all currencies basic information.
 * @param token
 * @param code
 */
export const getAllSystemVariables = (): Promise<SystemVariableResponse[]> => {
    return api
        .get(`/List`)
        .then((response: AxiosResponse<SystemVariableResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Get system variable basic information.
 * @param systemVariableID
 */
export const getSystemVariableDetails = (systemVariableID: number): Promise<SystemVariableResponse> => {
    return api
        .get(`/${systemVariableID}`)
        .then((response: AxiosResponse<SystemVariableResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
/**
 * Update system variable information.
 * @param token
 * @param systemVariableID
 * @param systemVariableRequest
 */
export const updateSystemVariable = (systemVariableID: number, systemVariableRequest: SystemVariableRequest): Promise<SystemVariableResponse> => {
    return api
        .post(`/${systemVariableID}`, systemVariableRequest)
        .then((response: AxiosResponse<SystemVariableResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Add new system variable.
 * @param token
 * @param systemVariableRequest
 */
export const addSystemVariable = (systemVariableRequest: SystemVariableRequest): Promise<SystemVariableResponse> => {
    return api
        .post(`/New`, systemVariableRequest)
        .then((response: AxiosResponse<SystemVariableResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Delete system variable.
 * @param token
 * @param currencyData
 */
export const deleteSystemVariable = (systemVariableId: number): Promise<void> => {
    return api
        .delete(`/${systemVariableId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
