import React from "react";
import {ItemField} from "../../../../Crudder/CrudModule";

import { FieldArray } from "react-final-form-arrays";
import StyledTextField from "../../../../Crudder/components/CrudDetail/StyledTextField";
import {FormApi} from "final-form";
import {Grid, Button, Box, Avatar, IconButton, InputAdornment} from "@material-ui/core";
import useStyles from "./styles";
import {useTheme} from "@material-ui/core/styles";
import {Delete} from "@material-ui/icons";
import { OrderResponse } from "../../../../Api/data/order/types";
import { Field } from "react-final-form";

interface IOrderItemsFormPartProps {
    field: ItemField<any>,
    readonly?: boolean,
    form: FormApi,
    data?: OrderResponse,
}

const OrderItemsFormPart: React.FC<IOrderItemsFormPartProps> = ({ field, readonly , form, data}) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <FieldArray name="items">
                {({ fields }) =>
                    fields.map((name, index) => (
                        <Box className={classes.itemRoot} key={`item-${name}`}>
                            <Box className={classes.itemNumber}>
                                <Avatar>{index + 1}</Avatar>
                                <Box className={classes.itemMenuHolder}>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => fields.remove(index)}
                                        size={"medium"}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Grid className={classes.itemForm} container spacing={3} key={name}>
                                <Grid item xs={10}>
                                    <StyledTextField
                                        name={`${name}.title`}
                                        label={"Nadpis"}
                                    />
                                    <StyledTextField
                                        name={`${name}.description`}
                                        multiline={true}
                                        rows={3}
                                        label={"Popis"}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Field name="currency">
                                        {props => (
                                            <StyledTextField
                                                name={`${name}.price`}
                                                type={"number"}
                                                label={"Cena"}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">{props.input.value?.alpha3Code}</InputAdornment>,
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </Box>

                    ))
                }
            </FieldArray>
        </>
    );
}
export default OrderItemsFormPart;