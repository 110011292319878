import React from "react";
import {Box, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

const RecipientsAutocomplete: React.FC<{props, options}> = ({props, options}) => {
    const [inputValue, setInputValue] = React.useState("");

    return (
        <Autocomplete
            multiple={true}
            freeSolo={true}
            inputValue={inputValue}
            value={props.input.value}
            options={options}
            // getOptionValue={option => option.value ?? option}
            // getOptionLabel={option => option.label ?? option}
            filterSelectedOptions
            onChange={(event, newValue) => {
                props.input.onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                const options = newInputValue.split(",");

                if (options.length > 1) {
                    props.input.onChange(
                        props.input.value
                            ?.concat(options)
                            ?.map(x => x.trim())
                            ?.filter(x => x)
                    );
                } else {
                    setInputValue(newInputValue);
                }
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    InputLabelProps={{
                        shrink: true
                    }}
                    variant={"outlined"}
                    label="Příjemci"
                    placeholder="Čárka , jako oddělovač"
                />
            )}
        />
    );
};

export default RecipientsAutocomplete;