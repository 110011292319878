import React, {useCallback, useState} from "react";
import CrudModule, {ItemField, OnSubmitDone} from "../../../Crudder/CrudModule";
import {CurrencyRequest, CurrencyResponse} from "../../../Api/data/currency/types";
import {addCurrency, deleteCurrency, getAllCurrencies, updateCurrency} from "../../../Api/data/currency/client";
import {useKeycloak} from "@react-keycloak/web";

const fields : ItemField<CurrencyRequest>[] = [
    {
        name: "currencyID",
        title: "Identifikátor",
        description: "Identifikátor",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "alpha3Code",
        title: "3 místný kód",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "symbol",
        title: "Symbol",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "name",
        title: "Název",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "displayFormat",
        title: "Formát zobrazování",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "exchangeRate",
        title: "Kurz",
        columnDef: {
            flex: 1
        },
    },
];


const CurrencyModule: React.FC = () => {

    const [ data, setData ] = useState<CurrencyResponse[]>([]);


    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<CurrencyResponse[]>((resolve, reject) => {
            getAllCurrencies().then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<OnSubmitDone>((resolve, reject) => {
            if (formValues["currencyID"]) {
                // update
                // @ts-ignore
                updateCurrency(formValues["currencyID"], formValues as CurrencyRequest).then((response) => {
                    resolve({id: response.currencyID});
                })
            }
            else {
                // create
                // @ts-ignore
                addCurrency(formValues as CurrencyRequest).then((response) => {
                    resolve({id: response.currencyID, inserted: true});
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteCurrency(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<any>((resolve, reject) => {
            resolve(data.find(i => i.currencyID == id));
        });
    }, [data]);


    return ( keycloakInitialized && keycloak?.token ? (
            <CrudModule<CurrencyResponse, CurrencyResponse>
                name={"Měny"}
                list={{
                    title: "Seznam měn",
                    getRowID: (currency) => currency.currencyID
                }}
                detail={{
                    title: "Detail měny",
                }}
                itemFields={fields as any}
                data={fetchData}
                getDetail={getDetail}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
            />
    ) : null

    );
};

export default CurrencyModule;