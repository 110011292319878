import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            padding: theme.spacing(2),
            margin: theme.spacing(2),
            "& .MuiTypography-caption": {
                margin: theme.spacing(-1),
                marginBottom: theme.spacing(1),
                fontSize: theme.typography.pxToRem(11),
                textTransform: 'uppercase',
                display: 'block',
                color: theme.palette.text.secondary,
            },
            "&:last-of-type": {
                marginBottom: 0,
            }
        },
        sectionAttachments: {
            marginBottom: 0,
        },
        number: {
            "&  input": {
                fontSize: "3rem"
            }

        },
        stateInput: {
            "&  .MuiSelect-select": {
                fontSize: "1.5rem"
            }
        },
        companyInput: {
            "&  .MuiSelect-select": {
                fontSize: "2rem"
            }
        },
        itemNumber: {
            width: "4em",
            maxWidth: "4em",
            flex: 1,
            position: "relative",
            "& .MuiAvatar-root": {
                marginTop: theme.spacing(1),
                backgroundColor: theme.palette.primary.main,
            }
        },
        itemNumberText: {
            transform: 'rotate(-90deg)',
            position: "absolute",
            textTransform: "uppercase",
            top: 0,
            left: 0,
            width: "4em",
            height: "1em",
        },
        itemActions: {
            width: "4em",
            maxWidth: "4em",
            flex: 1,
            padding: theme.spacing(2),
        },
        itemForm: {
            flex: 3,
        },
        itemRoot: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
        dropzoneFocused: {
            opacity: 0.5,
        },
        orderPreviewDialogContent: {
            height: "calc(100vh - 200px)",
            aspectRatio: "1 / 1.5",
            position: "relative",
        },
        sendMailDialogContent: {
            height: "calc(100vh - 200px)",
            position: "relative",
            overflowY: "scroll"
        },
        pdfWrapper: {
            width: "100% !important",
            height: "100% !important",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(1.5),
            color: theme.palette.grey[500],
        },
        sendMailForm: {
            overflowY: "auto",
            display: "flex",
            flexDirection: "column"
        },
        submitting: {
        },
        submitError: {
            marginTop: theme.spacing(1),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        speedDial: {
            position: "fixed",
            bottom: theme.spacing(4),
            right: theme.spacing(2),
        },
        backdrop: {
            zIndex: theme.zIndex.speedDial - 1
        },
        itemMenuHolder: {
            marginTop: theme.spacing(1),
        },
        paddedItem: {
            paddingTop: `${theme.spacing(3)} !important`,
        },
        estimatedDelivery: {
            "& input": {
                color: theme.palette.text.primary,
            }
        }
    })
);

export default useStyles;
