import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultFooterHeight } from "../Layout/Layout";
import { StylesProps } from "./Footer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: StylesProps | undefined) => ({
            color: props?.footerBarColor ? props?.footerBarColor : theme.palette.grey["500"],
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.primary.dark,
            minHeight: 0,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            [theme.breakpoints.up("xs")]: {},
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        content: (props: StylesProps | undefined) => ({
            color: props?.footerBarColor ? props?.footerBarColor : theme.palette.grey["500"],
            background: props?.footerBarBackground ? props?.footerBarBackground : theme.palette.primary.dark,
            flexGrow: 1,
            flexDirection: "column",
            alignItems: "center",
            padding: "0 .4rem",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                flexWrap: "nowrap",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
            [theme.breakpoints.up("lg")]: {
                alignItems: "flex-start",
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        inline: {
            flexFlow: "row nowrap !important",
            height: "20px",
            "& .copyright": {
                textAlign: "right",
            },
        },
        paragraph: (props: StylesProps | undefined) => ({
            fontSize: "0.7rem",
            lineHeight: ".8rem",
            [theme.breakpoints.up("md")]: {
                height: props?.footerHeight ? props?.footerHeight : defaultFooterHeight,
            },
        }),
        logo: {
            display: "inline-block",
            marginLeft: "0.3rem",
            "& img": {
                height: ".8rem",
                transform: "scale(1.2)",
                [theme.breakpoints.up("md")]: {
                    // marginTop: ".5rem",
                    marginBottom: "-0.1rem",
                },
            },
            "&.client img": {
                [theme.breakpoints.up("md")]: {
                    marginBottom: "0.05rem",
                },
            },
        },
        copyLogo: {
            marginTop: ".2rem",
            [theme.breakpoints.up("md")]: {
                order: 1,
                alignSelf: "center",
            },
        },
        copyAuthorLogo: {
            marginTop: ".2rem",
        },
        copyUpdate: {
            textAlign: "center",
            paddingTop: "2px",
            paddingBottom: "2px",
            [theme.breakpoints.up("md")]: {
                order: 3,
                alignSelf: "flex-end",
                textAlign: "right",
                paddingTop: "4px",
                paddingBottom: 0,
            },
        },
        copyIolabs: {
            textAlign: "center",
            paddingTop: "2px",
            paddingBottom: "2px",
            [theme.breakpoints.up("md")]: {
                order: 2,
                alignSelf: "flex-center",
                textAlign: "center",
                width: "43%",
                paddingTop: "0.2rem",
                paddingBottom: 0,
            },
            "& a": {
                verticalAlign: "middle",
            },
        },
        lastUpdate: {
            cursor: "help",
        },
    })
);

export default useStyles;
