import React, { createContext, PropsWithChildren } from "react";
import { DF } from "../../components/DialogFactory/DialogFactory";
import DialogWrapper from "../DialogWrapper/DialogWrapper";
import useDialog, { IDialogConfig } from "./useDialog";

export interface IDialogContext {
    dialogs: IDialogConfig[];
    toggleDialog: (id: DF, open?: boolean) => void;
    createDialog: (newDialog: IDialogConfig) => void;
}

export const DialogContext = createContext<IDialogContext>({
    dialogs: [],
    createDialog: () => {},
    toggleDialog: () => {},
});

interface IDialogProviderProps {}

const DialogProvider: React.FC<PropsWithChildren<IDialogProviderProps>> = ({ children }) => {
    const dialog = useDialog();

    return (
        <DialogContext.Provider value={dialog}>
            <DialogWrapper />
            {children}
        </DialogContext.Provider>
    );
};

export default DialogProvider;
