import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { IFeedbackSendRequestData, IFeedbackSendResponseData } from "../../packages/Api/data/feedback/types";

export enum ActionTypes {
    // feedback send
    OnFeedbackSend = "feedback/ON_FEEDBACK_SEND",
    OnFeedbackSendDone = "issues/ON_FEEDBACK_SEND_DONE",
    OnFeedbackSendFail = "issues/ON_FEEDBACK_SEND_FAIL",
}

// feedback send
export type OnFeedbackSendOptions = {
    token: string;
    data: IFeedbackSendRequestData;
    messageSuccess: string;
    messageError: string;
};
export type OnFeedbackSendDoneOptions = {
    response: IFeedbackSendResponseData;
};
export type OnFeedbackSendFailOptions = {
    error?: any;
};

export const Actions = {
    // feedback send
    onFeedbackSend: (options: OnFeedbackSendOptions) => createAction(ActionTypes.OnFeedbackSend, options),
    onFeedbackSendDone: (options: OnFeedbackSendDoneOptions) => createAction(ActionTypes.OnFeedbackSendDone, options),
    onFeedbackSendFail: (options: OnFeedbackSendFailOptions) => createAction(ActionTypes.OnFeedbackSendFail, options),
};

// feedback send
export function onFeedbackSend(options: OnFeedbackSendOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onFeedbackSend(options));
    };
}
export function onFeedbackSendDone(options: OnFeedbackSendDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onFeedbackSendDone(options));
    };
}
export function onFeedbackSendFail(options: OnFeedbackSendFailOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onFeedbackSendFail(options));
    };
}
