import React, {useEffect, useState} from "react";
import {Backdrop, Box, Grid, IconButton, InputAdornment, MenuItem, Paper, Typography} from "@material-ui/core";
import StyledTextField from "../../../../Crudder/components/CrudDetail/StyledTextField";
import {CompanyResponse} from "../../../../Api/data/company/types";
import {getAllCompanies} from "../../../../Api/data/company/client";
import InputSelect from "../../../../Crudder/components/InputSelect";
import {CurrencyResponse} from "../../../../Api/data/currency/types";
import {getAllCurrencies} from "../../../../Api/data/currency/client";
import OrderItemsFormPart from "./OrderItemsFormPart";
import {IFormTemplate} from "../../../../Crudder/components/CrudDetail/CrudDetailForm";
import {Autocomplete, TextField} from "mui-rff";
import useStyles from "./styles";
import InputDate from "../../../../Crudder/components/InputDate";
import Dropzone from "react-dropzone";
import FileDropZone from "../../FileModule/components/FileDropZone";
import {Field, useForm} from "react-final-form";
import {OnChange} from "react-final-form-listeners";
import {OrderResponse, OrderState, RealizationPlace} from "../../../../Api/data/order/types";
import OrderFileList from "./OrderFileList";
import {Alert, SpeedDial, SpeedDialAction, SpeedDialIcon} from "@material-ui/lab";
import {uploadFile} from "../../../../Api/data/file/client";
import clsx from "clsx";
import {Add, Send, Visibility} from "@material-ui/icons";
import OrderPreview from "./OrderPreview";
import OrderSendMail from "./OrderSendMail";
import {SelectMode} from "../../../../Crudder/components/CrudList/CrudList";
import {normalizeStateLabel} from "../../../../Crudder/utils/utils";

interface IProcessingFileProps {
    name: string,
    progress: number,
}

const OrderForm: React.FC<IFormTemplate<OrderResponse>> = ({ data}) => {
    const classes = useStyles();
    // const formState = useFormState();

    const readonly = false;

    const form = useForm();
    const [ processingFiles, setProcessingFiles ] = useState<{[key: string]: IProcessingFileProps}>({});
    const [ doneFiles, setDoneFiles ] = useState<number[]>([]);
    const [ showPdfPreview, setShowPdfPreview ] = useState<boolean>(false);
    const [ showSendEmail, setShowSendEmail ] = useState<boolean>(false);
    const [ sendEmailAttachments, setSendEmailAttachments ] = useState<number[]>([]);

    const [ filesCounterState, setFilesCounterState ] = useState<number>(1);

    const [ currencies, setCurrencies ] = useState<CurrencyResponse[]>();
    const [ companies, setCompanies ] = useState<CompanyResponse[]>();
    const [ speedDialOpen, setSpeedDialOpen ] = useState<boolean>(false);

    useEffect(() => {
        getAllCurrencies().then((res) => {
            setCurrencies(res)
        });
        getAllCompanies().then((res) => {
            setCompanies(res)
        });
    }, []);

    // const {
    //     getRootProps,
    //     getInputProps,
    //     isFocused,
    //     isDragAccept,
    //     isDragReject,
    //     acceptedFiles
    // } = useDropzone( {
    //     noClick: true,
    //     onDrop: (acceptedFiles: File[]) => {
    //
    //         acceptedFiles.forEach(af => {
    //             setProcessingFiles(prevFiles => {
    //                 if (!prevFiles[af.name]) {
    //                     prevFiles[af.name] = {
    //                         name: af.name,
    //                         progress: 0
    //                     }
    //                 }
    //                 return {...prevFiles};
    //             })
    //             const formData = new FormData();
    //             formData.set("data", af.slice(), af.name);
    //             uploadFile(formData, (progress) => {
    //                 setProcessingFiles(prevFiles => {
    //                     prevFiles[af.name].progress = progress;
    //                     return {...prevFiles};
    //                 })
    //             }).then(f => {
    //                 console.log(f)
    //                 setDoneFiles((prevDone) => [...prevDone, f.fileID]);
    //             })
    //                 .catch(f => {
    //                     console.error(f)
    //                 })
    //         });
    //
    //     }
    // });

    const onDrop = (acceptedFiles: File[]) => {

        acceptedFiles.forEach(af => {
            setProcessingFiles(prevFiles => {
                if (!prevFiles[af.name]) {
                    prevFiles[af.name] = {
                        name: af.name,
                        progress: 0
                    }
                }
                return {...prevFiles};
            })
            const formData = new FormData();
            formData.set("data", af.slice(), af.name);
            uploadFile(formData, (progress) => {
                setProcessingFiles(prevFiles => {
                    prevFiles[af.name].progress = progress;
                    return {...prevFiles};
                })
            }).then(f => {
                console.log(f)
                setDoneFiles((prevDone) => [...prevDone, f.fileID]);
            })
                .catch(f => {
                    console.error(f)
                })
        });

    }

    const handleShowPreview = () => {
        setShowPdfPreview(true);
    }

    const handleClosePreview = (filesUpdated?: boolean, fileToSend?: number) => {
        setShowPdfPreview(false);
        if (filesUpdated) {
            setFilesCounterState(prevState => prevState + 1);
        }
        if (fileToSend) {
            setSendEmailAttachments([fileToSend]);
            setShowSendEmail(true);
        }

    }


    const handleShowSend = () => {
        setShowSendEmail(true);
    }
    const handleCloseSend = (stateUpdated?: boolean) => {
        setShowSendEmail(false);
    }

    return (
        <>
            {showPdfPreview && data && <OrderPreview order={data} onClose={handleClosePreview} />}
            {showSendEmail && data && <OrderSendMail order={data} onClose={handleCloseSend} selectedAttachments={sendEmailAttachments} />}
            <Dropzone onDrop={onDrop} noClick>
                {({getRootProps, getInputProps, isDragAccept, open: openDropzone}) => (
                    <Box {...getRootProps()}>
                        <Box className={clsx("dropzone", {[classes.dropzoneFocused]: isDragAccept})}>
                            <Paper className={classes.section}>
                                <Typography variant={"caption"}>Základní údaje</Typography>
                                <Grid container spacing={4}>
                                    <Grid item xs={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <StyledTextField
                                                    className={classes.number}
                                                    name={"number"}
                                                    label={"Číslo"}
                                                    // type={"number"}
                                                    // loading={loading}
                                                    // submitting={submitting}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputSelect
                                                    field={{name: "orderState", title: "Stav"}}
                                                    readonly={readonly}
                                                    className={classes.stateInput}
                                                    itemsGetter={async (search) => {
                                                        return [
                                                            <MenuItem value={OrderState.New} key={OrderState.New}>{normalizeStateLabel(OrderState.New)}</MenuItem>,
                                                            <MenuItem value={OrderState.QuoteSend} key={OrderState.QuoteSend}>{normalizeStateLabel(OrderState.QuoteSend)}</MenuItem>,
                                                            <MenuItem value={OrderState.Ordered} key={OrderState.Ordered}>{normalizeStateLabel(OrderState.Ordered)}</MenuItem>,
                                                            <MenuItem value={OrderState.Invoiced} key={OrderState.Invoiced}>{normalizeStateLabel(OrderState.Invoiced)}</MenuItem>,
                                                            <MenuItem value={OrderState.Paid} key={OrderState.Paid}>{normalizeStateLabel(OrderState.Paid)}</MenuItem>,
                                                        ]
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                {/*<InputSelect*/}
                                                {/*    field={{name: "companyID", title: "Company"}}*/}
                                                {/*    readonly={readonly}*/}
                                                {/*    className={classes.companyInput}*/}
                                                {/*    itemsGetter={async (search) => {*/}
                                                {/*        const companies: CompanyResponse[] = await getAllCompanies();*/}
                                                {/*        return companies.map(c => (*/}
                                                {/*            <MenuItem key={`mi-c-${c.companyID}`} value={c.companyID}>{c.name}</MenuItem>*/}
                                                {/*        ))*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                                { companies && (
                                                    <>
                                                        <Autocomplete
                                                            options={companies??[]}
                                                            getOptionValue={option => option.companyID}
                                                            getOptionLabel={option => option.name as string}
                                                            filterSelectedOptions
                                                            name={"companyID"}
                                                            label={"Firma"}
                                                            autoHighlight
                                                            className={classes.companyInput}
                                                            textFieldProps={
                                                                {
                                                                    variant: "outlined",
                                                                    // inputProps: {
                                                                    //     readOnly: submitting || loading
                                                                    // },
                                                                    // inputLabelProps: {
                                                                    //     shrink: true,
                                                                    // }
                                                                }
                                                            }
                                                        />
                                                        <OnChange name="companyID">
                                                            {(value, previous) => {
                                                                if (value !== previous) {
                                                                    const selectedCompany = companies.find(c => c.companyID == value);
                                                                    form.mutators.setCompany(selectedCompany);
                                                                }

                                                            }}
                                                        </OnChange>
                                                    </>
                                                )}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <InputDate
                                                    name="date"
                                                    label="Datum realizace"
                                                    placeholder="Datum realizace"
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <InputSelect field={{name: "realizationPlace", title: "Místo realizace"}} readonly={readonly} itemsGetter={async (search) => {
                                                    return [
                                                        <MenuItem value={RealizationPlace.Internally} key={RealizationPlace.Internally}>Internally</MenuItem>,
                                                        <MenuItem value={RealizationPlace.Externally} key={RealizationPlace.Externally}>Externally</MenuItem>,
                                                    ]
                                                }} />
                                            </Grid>

                                            <Grid item xs={3}>

                                            </Grid>
                                            <Grid item xs={3}>

                                                { currencies && (
                                                    <>
                                                        <InputSelect
                                                            field={{name: "currencyID", title: "Měna"}}
                                                            readonly={readonly}
                                                            itemsGetter={async (search) => {
                                                                return currencies.map(c => (
                                                                    <MenuItem key={`mi-cur-${c.currencyID}`} value={c.currencyID}>{c.alpha3Code}</MenuItem>
                                                                ))
                                                            }}
                                                        />
                                                        <OnChange name="currencyID">
                                                            {(value, previous) => {
                                                                if (value !== previous) {
                                                                    const selectedCurrency = currencies.find(c => c.currencyID == value);
                                                                    form.mutators.setCurrency(selectedCurrency);
                                                                }

                                                            }}
                                                        </OnChange>
                                                    </>
                                                )}

                                            </Grid>

                                            <Grid item xs={3}>
                                                <InputDate
                                                    name="quoteDate"
                                                    label="Datum odeslání nabídky"
                                                    readOnly={true}
                                                    placeholder="Datum odeslání nabídky"
                                                />
                                            </Grid>

                                            <Grid item xs={3}>
                                                <StyledTextField
                                                    name="invoiceNumber"
                                                    label="Číslo faktury"
                                                    placeholder="Číslo faktury"
                                                />
                                            </Grid>

                                            {/*<Grid item xs={3}>*/}
                                            {/*    <StyledTextField*/}
                                            {/*        name="currency.exchangeRate"*/}
                                            {/*        label="Exchange Rate"*/}
                                            {/*        disabled={true}*/}
                                            {/*        // value={selectedCurrency?.exchangeRate ?? undefined}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}


                                            {/*<Grid item xs={3}>*/}

                                            {/*    <Button onClick={handleShowPreview}>Generate</Button>*/}
                                            {/*</Grid>*/}
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className={classes.section}>
                                <Typography variant={"caption"}>Položky nabídky</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <OrderItemsFormPart field={{name: "items", title: "Položky"}} readonly={readonly} form={form} data={data}  />
                                    </Grid>
                                </Grid>

                                <Box className={classes.itemRoot}>
                                    <Box className={classes.itemNumber}>
                                        <IconButton
                                            type="button"
                                            color={"primary"}
                                            onClick={() => form.mutators.push("items", undefined)}
                                            size={"medium"}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Box>

                                    <Grid className={classes.itemForm} container spacing={3}>

                                        <Grid item xs={2}>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Field name="company.estimatedDelivery">
                                                {props => (
                                                    <StyledTextField
                                                        name="company.estimatedDelivery.total"
                                                        label="Orientační cena dopravy"
                                                        disabled={true}
                                                        className={classes.estimatedDelivery}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{`${props.input.value.distanceKm??0} km x ${props.input.value.pricePerKm??0} CZK + ${props.input.value.distanceH??0} h x ${props.input.value.pricePerH??0} CZK =`}</InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">CZK</InputAdornment>,
                                                            readOnly: true
                                                        }}
                                                        // value={selectedCurrency?.exchangeRate ?? undefined}
                                                    />

                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={1} className={classes.paddedItem}>

                                        </Grid>
                                        <Grid item xs={2} className={classes.paddedItem}>
                                            <Typography variant={"h4"}>Celkem</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Field name="currency">
                                                {props => (
                                                    <StyledTextField
                                                        name={"total"}
                                                        label={"Celkem"}
                                                        type={"number"}
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">{props.input.value?.alpha3Code}</InputAdornment>,
                                                            readOnly: true
                                                        }}
                                                        // loading={loading}
                                                        // submitting={submitting}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>

                                </Box>

                            </Paper>

                            {/*<Paper className={classes.section}>*/}
                            {/*    <Grid container spacing={3}>*/}


                            {/*        <Grid item xs={4}>*/}
                            {/*            <StyledTextField*/}
                            {/*                name={"formName"}*/}
                            {/*                label={"Form name"}*/}
                            {/*                type={"text"}*/}
                            {/*                loading={loading}*/}
                            {/*                submitting={submitting}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={4}>*/}
                            {/*            <StyledTextField*/}
                            {/*                name={"typeOfRepaor"}*/}
                            {/*                label={"Type of repair"}*/}
                            {/*                type={"text"}*/}
                            {/*                loading={loading}*/}
                            {/*                submitting={submitting}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={3}>*/}
                            {/*            <StyledTextField*/}
                            {/*                name={"commissionNumber"}*/}
                            {/*                label={"Commission Number"}*/}
                            {/*                type={"text"}*/}
                            {/*                loading={loading}*/}
                            {/*                submitting={submitting}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={3}>*/}
                            {/*            <StyledTextField*/}
                            {/*                name={"invoiceNumber"}*/}
                            {/*                label={"Invoice Number"}*/}
                            {/*                type={"text"}*/}
                            {/*                loading={loading}*/}
                            {/*                submitting={submitting}*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={3}>*/}
                            {/*            <InputDate*/}
                            {/*                name="dueDate"*/}
                            {/*                label="Due date"*/}
                            {/*                placeholder="Due date"*/}
                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item xs={3}>*/}
                            {/*            <InputDate*/}
                            {/*                name="payDate"*/}
                            {/*                label="Pay date"*/}
                            {/*                placeholder="Pay date"*/}
                            {/*            />*/}
                            {/*        </Grid>*/}


                            {/*    </Grid>*/}
                            {/*</Paper>*/}
                            <Paper className={classes.section}>
                                <Typography variant={"caption"}>Doplňující údaje</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            name={"note"}
                                            label={"Poznámka"}
                                            placeholder={"Poznámka"}
                                            type={"text"}
                                            fullWidth
                                            rows={4}
                                            // margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            multiline={true}
                                            // InputProps={{
                                            //     readOnly: submitting || loading,
                                            // }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>

                            <Paper className={clsx(classes.section, classes.sectionAttachments)}>
                                <Typography variant={"caption"}>Přílohy</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        {
                                            data?.orderID ? (
                                                <OrderFileList
                                                    key={`files-state-${filesCounterState}`}
                                                    orderID={data.orderID}
                                                    crudListProps={{
                                                        density: "compact",
                                                        selectMode: SelectMode.NONE,
                                                        disableEdit: true,
                                                    }}
                                                />
                                            ) : (
                                                <Alert severity={"info"} title={"No files"}>Žádné přílohy</Alert>
                                            )
                                        }

                                    </Grid>
                                    <Grid item xs={12}>


                                        <Typography variant={"caption"}>K přidání</Typography>
                                        <Field name={"files"}>
                                            {props => (
                                                <>
                                                    <FileDropZone
                                                        onChange={props.input.onChange}
                                                        value={props.input.value}
                                                        processingFiles={processingFiles}
                                                        doneFiles={doneFiles}
                                                        open={openDropzone}
                                                    />
                                                </>
                                            )}
                                        </Field>

                                    </Grid>
                                </Grid>
                            </Paper>


                            {!!data?.orderID && (
                                <>
                                    <Backdrop
                                        className={classes.backdrop}
                                        open={speedDialOpen}
                                    />
                                    <SpeedDial
                                        ariaLabel="SpeedDial example"
                                        className={classes.speedDial}
                                        icon={<SpeedDialIcon />}
                                        onClose={() => setSpeedDialOpen(false)}
                                        onOpen={() => setSpeedDialOpen(true)}
                                        open={speedDialOpen}
                                        direction={"up"}
                                    >
                                        <SpeedDialAction
                                            icon={<Send />}
                                            tooltipTitle={"Odeslat"}
                                            onClick={handleShowSend}
                                            tooltipOpen
                                        />
                                        <SpeedDialAction
                                            icon={<Visibility />}
                                            tooltipTitle={"Náhled"}
                                            onClick={handleShowPreview}
                                            tooltipOpen
                                        />
                                    </SpeedDial>
                                </>
                            )}

                        </Box>
                    </Box>
                    )}
            </Dropzone>
        </>
    );
}
export default OrderForm;