import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {
    CompanyRequest,
    CompanyResponse
} from "./types";
import {OrderResponse} from "../order/types";

const api = new ApiClient("/Company", config.api.baseUrl);

/**
 * Get all currencies basic information.
 * @param token
 * @param code
 */
export const getAllCompanies = (): Promise<CompanyResponse[]> => {
    return api
        .get(`/List`)
        .then((response: AxiosResponse<CompanyResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Get company basic information.
 * @param companyId
 */
export const getCompanyDetails = (companyId: number): Promise<CompanyResponse> => {
    return api
        .get(`/${companyId}`)
        .then((response: AxiosResponse<CompanyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Update company information.
 * @param token
 * @param companyID
 * @param companyData
 */
export const updateCompany = (companyId: number, companyData: CompanyRequest): Promise<CompanyResponse> => {
    return api
        .post(`/${companyId}`, companyData)
        .then((response: AxiosResponse<CompanyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Add new company.
 * @param token
 * @param companyData
 */
export const addCompany = (companyData: CompanyRequest): Promise<CompanyResponse> => {
    return api
        .post(`/New`, companyData)
        .then((response: AxiosResponse<CompanyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Delete company.
 * @param token
 * @param companyData
 */
export const deleteCompany = (companyId: number): Promise<void> => {
    return api
        .delete(`/${companyId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
