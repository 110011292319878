(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react-intl"), require("react"), require("@material-ui/core"), require("@material-ui/core/DialogActions"), require("@material-ui/core/DialogContent"), require("@material-ui/core/DialogTitle"), require("@material-ui/core/Typography"), require("@material-ui/icons/Close"), require("@material-ui/styles"));
	else if(typeof define === 'function' && define.amd)
		define("WipDialog", ["react-intl", "react", "@material-ui/core", "@material-ui/core/DialogActions", "@material-ui/core/DialogContent", "@material-ui/core/DialogTitle", "@material-ui/core/Typography", "@material-ui/icons/Close", "@material-ui/styles"], factory);
	else if(typeof exports === 'object')
		exports["WipDialog"] = factory(require("react-intl"), require("react"), require("@material-ui/core"), require("@material-ui/core/DialogActions"), require("@material-ui/core/DialogContent"), require("@material-ui/core/DialogTitle"), require("@material-ui/core/Typography"), require("@material-ui/icons/Close"), require("@material-ui/styles"));
	else
		root["WipDialog"] = factory(root["react-intl"], root["react"], root["@material-ui/core"], root["@material-ui/core/DialogActions"], root["@material-ui/core/DialogContent"], root["@material-ui/core/DialogTitle"], root["@material-ui/core/Typography"], root["@material-ui/icons/Close"], root["@material-ui/styles"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__) {
return 