(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@iolabs/notifier"), require("@iolabs/redux-utils"), require("@material-ui/core"), require("@material-ui/styles"), require("notistack"), require("react"), require("react-keycloak"), require("react-redux"), require("reselect"));
	else if(typeof define === 'function' && define.amd)
		define(["@iolabs/notifier", "@iolabs/redux-utils", "@material-ui/core", "@material-ui/styles", "notistack", "react", "react-keycloak", "react-redux", "reselect"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@iolabs/notifier"), require("@iolabs/redux-utils"), require("@material-ui/core"), require("@material-ui/styles"), require("notistack"), require("react"), require("react-keycloak"), require("react-redux"), require("reselect")) : factory(root["@iolabs/notifier"], root["@iolabs/redux-utils"], root["@material-ui/core"], root["@material-ui/styles"], root["notistack"], root["react"], root["react-keycloak"], root["react-redux"], root["reselect"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__16__) {
return 