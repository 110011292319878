import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        section: {
            padding: theme.spacing(2),
            margin: theme.spacing(2),
            "& .MuiTypography-caption": {
                margin: theme.spacing(-1),
                marginBottom: theme.spacing(1),
                fontSize: theme.typography.pxToRem(11),
                textTransform: 'uppercase',
                display: 'block'
            }
        },
        itemActions: {
            width: "4em",
            maxWidth: "4em",
            flex: 1,
        },
        itemForm: {
            flex: 3,
        },
        itemRoot: {
            display: "flex",
            marginBottom: theme.spacing(2),
        },
    })
);

export default useStyles;
