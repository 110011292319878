import React, { isValidElement } from "react";
import Icons, { IIcon } from "./IconSet";

export interface IIconProps {
    clipRule?: string;
    fill?: string;
    fillRule?: string;
    height?: number;
    name: IIcon;
    size?: number;
    stroke?: string;
    strokeWidth?: number;
    className?: string;
    width?: number;
    viewBox?: string;
}

interface IArrayProps {
    [keys: string]: any;
}

interface ISvgIconProps extends IIconProps {
    svgs: IArrayProps;
}

const defaultProps = {
    fill: "currentColor",
    height: 24,
    width: 24,
    viewBox: "0 0 24 24",
};

const SvgIcon: React.FC<ISvgIconProps> = (props: ISvgIconProps) => {
    if (!props.name) {
        return null;
    }

    const name = props.svgs[props.name];

    if (!name) {
        return null;
    }

    const clipRule = props.clipRule ? props.clipRule : name.clipRule;
    const fill = props.fill ? props.fill : defaultProps.fill;
    const fillRule = props.fillRule ? props.fillRule : name.fillRule;

    const height = props.height
        ? props.height.toString()
        : props.size
        ? props.size.toString()
        : name.height
        ? name.height.toString()
        : defaultProps.height.toString();
    const width = props.width
        ? props.width.toString()
        : props.size
        ? props.size.toString()
        : name.width
        ? name.width.toString()
        : defaultProps.width.toString();

    const isSimple = isValidElement(name);
    const svgEl = isSimple ? name : name.svg;

    let viewBox;

    if (viewBox && viewBox !== defaultProps.viewBox) {
        viewBox = props.viewBox;
    } else if (!isSimple && name.viewBox) {
        viewBox = name.viewBox;
    } else {
        viewBox = defaultProps.viewBox;
    }

    return (
        <svg height={height} width={width} viewBox={viewBox} fill={fill} className={props.className}>
            {React.cloneElement(svgEl, {
                clipRule,
                fill,
                fillRule,
            })}
        </svg>
    );
};

const Icon: React.FC<IIconProps> = (props: IIconProps) => {
    return <SvgIcon {...props} svgs={Icons} />;
};

export default Icon;
