import React, {useEffect, useState} from "react";
import useStyles from "./PanelMenu/styles";
import {useLocation} from "react-router-dom";
import {CircularProgress, IconButton, InputAdornment, MenuItem} from "@material-ui/core";
import {ItemField} from "../CrudModule";
import {TextField} from "mui-rff";

interface IInputSelectProps {
    field: ItemField<any>,
    readonly?: boolean,
    itemsGetter: (search: string) => Promise<JSX.Element[]>,
    className?: string,
    autocomplete?: boolean,
}

const InputSelect: React.FC<IInputSelectProps> = ({field, readonly, itemsGetter, className}) => {
    const classes = useStyles();
    const location  = useLocation();

    const [ options, setOptions ] = useState<JSX.Element[]>([]);

    const [ loading, setLoading ] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        itemsGetter("").then(res => {
            setOptions(res);
        }).finally(() => {
            setLoading(false)
        })
    }, []);


    return (
        <TextField
            key={`field-${field.name}`}
            className={className??''}
            required
            name={field.name}
            label={field.title}
            placeholder={field.title}
            type={field.formFieldDef?.type ? field.formFieldDef.type : "text"}
            fullWidth
            // margin="normal"
            variant="outlined"
            color="primary"
            select={true}
            InputProps={{
                readOnly: readonly,
                endAdornment: loading && (
                    <InputAdornment position="start">
                        <IconButton>
                            <CircularProgress size={20} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
            InputLabelProps={{
                shrink: true,
            }}
        >
            {options}
        </TextField>
    )
}

export default InputSelect;