import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";

export interface ICrudderContext {
    detailStateCounter?: number;
    listStateCounter?: number;
    invalidateList: () => void;
    invalidateDetail: () => void;
}


export const CrudderContext = createContext<ICrudderContext>({
    invalidateList: () => {},
    invalidateDetail: () => {},
});


export const useCrudderContext = () => {
    const { detailStateCounter, invalidateDetail, listStateCounter, invalidateList } = useContext<ICrudderContext>(CrudderContext)

    return {
        detailStateCounter, invalidateDetail, listStateCounter, invalidateList
    }
}


interface ICrudderProviderProps {
}


const CrudderProvider: React.FC<PropsWithChildren<ICrudderProviderProps>> = ({ children }) => {
    const [ detailStateCounter, setDetailStateCounter ] = useState<number>(0);
    const [ listStateCounter, setListStateCounter ] = useState<number>(0);


    const invalidateDetail = () => {
        setDetailStateCounter(prev => prev + 1);
    }

    const invalidateList = () => {
        setListStateCounter(prev => prev + 1);
    }

    const value = { detailStateCounter, invalidateDetail, invalidateList, listStateCounter };

    return <CrudderContext.Provider value={value}>{children}</CrudderContext.Provider>
}
export default CrudderProvider
