import React from "react";
import {DatePicker, DatePickerProps} from "mui-rff";
import MomentUtils from "@date-io/moment";

interface IInputDateProps extends DatePickerProps {
}

const InputDate: React.FC<IInputDateProps> = (props) => {

    return (
        <DatePicker
            dateFunsUtils={MomentUtils}
            inputVariant={"outlined"}
            format={'D. M. YYYY'}
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    )
}

export default InputDate;