import React, {useCallback, useState} from "react";
import CrudModule, {ItemField, OnSubmitDone} from "../../../Crudder/CrudModule";
import {FileRequest, FileResponse} from "../../../Api/data/file/types";
import {addFile, deleteFile, getAllCurrencies, updateFile} from "../../../Api/data/file/client";
import {useKeycloak} from "@react-keycloak/web";

const fields : ItemField<any>[] = [
    {
        name: "fileID",
        title: "Identifikátor",
        description: "Identifikátor",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "guid",
        title: "Guid",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "name",
        title: "Název",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "size",
        title: "Velikost",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "mimeType",
        title: "Typ",
        columnDef: {
            flex: 2
        },
    },
];


const FileModule: React.FC = () => {

    const [ data, setData ] = useState<FileResponse[]>([]);


    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<FileResponse[]>((resolve, reject) => {
            getAllCurrencies().then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<OnSubmitDone>((resolve, reject) => {
            if (formValues["fileID"]) {
                // update
                // @ts-ignore
                updateFile(formValues["fileID"], formValues as FileRequest).then((response) => {
                    resolve({id: response.fileID});
                })
            }
            else {
                // create
                // @ts-ignore
                addFile(formValues as FileRequest).then((response) => {
                    resolve({id: response.fileID, inserted: true});
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteFile(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<any>((resolve, reject) => {
            resolve(data.find(i => i.fileID == id));
        });
    }, [data]);


    return ( keycloakInitialized && keycloak?.token ? (
            <CrudModule<FileResponse, FileResponse>
                name={"Soubory"}
                list={{
                    title: "Seznam souborů",
                    getRowID: (file) => file.fileID
                }}
                detail={{
                    title: "Detail souboru",
                }}
                itemFields={fields}
                data={fetchData}
                getDetail={getDetail}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
            />
    ) : null

    );
};

export default FileModule;