/**
 * Detect active menu item
 * @param url
 * @param item
 */
export const isMenuItemActive = (url: string, item: string): boolean => {
    const urls = url.split("/");
    const items = item.split("/");

    return items[1].startsWith(urls[1]);
};
