/**
 * Interfaces
 */

import {CompanyResponse} from "../company/types";
import {CurrencyResponse} from "../currency/types";


/** Order item request. */
export interface OrderItemRequest {
    /** Title. */
    title?: string | undefined;
    /** Description. */
    description?: string | undefined;
    /** Price. */
    price?: number;
}

/** Order item response. */
export interface OrderItemResponse {
    /** Title. */
    title?: string | undefined;
    /** Description. */
    description?: string | undefined;
    /** Price. */
    price?: number;
    /** Identity. */
    orderItemID: number;
}

/** Order request. */
export interface OrderRequest {
    /** Number. */
    number?: string | undefined;
    /** Realization date. */
    date?: string;
    /** Quote date. */
    quoteDate?: string;
    /** Commission Number. */
    commissionNumber?: string | undefined;
    /** Invoice Number. */
    invoiceNumber?: string | undefined;
    /** Exchange rate. */
    exchangeRate?: number;
    /** Note. */
    note?: string | undefined;
    /** Company ID FK. */
    companyID?: number | undefined;
    /** Currency ID FK. */
    currencyID?: number | undefined;
    orderState?: OrderState;
    realizationPlace?: RealizationPlace;
    /** Order items. */
    items?: OrderItemRequest[] | undefined;
    /** File identifiers to attach. */
    files?: number[] | undefined;
}

/** Order response. */
export interface OrderResponse {
    /** Number. */
    number?: string | undefined;
    /** Realization date. */
    date?: Date;
    /** Quote date. */
    quoteDate?: Date;
    /** Commission Number. */
    commissionNumber?: string | undefined;
    /** Invoice Number. */
    invoiceNumber?: string | undefined;
    /** Exchange rate. */
    exchangeRate?: number;
    /** Note. */
    note?: string | undefined;
    /** Company ID FK. */
    companyID?: number | undefined;
    /** Currency ID FK. */
    currencyID?: number | undefined;
    orderState?: OrderState;
    realizationPlace?: RealizationPlace;
    /** Order items. */
    items?: OrderItemResponse[] | undefined;
    /** Identity. */
    orderID: number;
    company?: CompanyResponse;
    currency?: CurrencyResponse;
    /** Order files. */
    orderFiles?: OrderFileResponse[] | undefined;
    total?: number | undefined;
}

export enum OrderState {
    New = "New",
    QuoteSend = "QuoteSend",
    Ordered = "Ordered",
    Invoiced = "Invoiced",
    Paid = "Paid",
}

export enum RealizationPlace {
    Internally = "Internally",
    Externally = "Externally",
}

export interface OrderFileResponse {
    /** Name. */
    name: string;
    /** Mime Type. */
    mimeType?: string | undefined;
    /** Line. */
    size?: number;
    /** Identity. */
    fileID: number;
    /** Identity GUID. */
    guid?: string;
    /** Order file Identity. */
    orderFileID: number;
}

export interface OrderTempPdf {
    /** Data. */
    data: Blob;
    /** Temp file Identifier. */
    id: number;
}

/** Send mail API model. */
export interface SendMailDto {
    /** Subject. */
    subject: string;
    /** Body. */
    body?: string | undefined;
    /** Recipients. */
    recipients?: string[] | undefined;
    /** Recipients. */
    attachments?: number[] | undefined;
}

