import React, {useCallback, useState} from "react";
import CrudModule, {ItemField, OnSubmitDone} from "../../../Crudder/CrudModule";
import {CurrencyRequest, CurrencyResponse} from "../../../Api/data/currency/types";
import {addCurrency, deleteCurrency, getAllCurrencies, updateCurrency} from "../../../Api/data/currency/client";
import {useKeycloak} from "@react-keycloak/web";
import {CompanyRequest, CompanyResponse} from "../../../Api/data/company/types";
import {addCompany, deleteCompany, getAllCompanies, updateCompany, getCompanyDetails} from "../../../Api/data/company/client";
import OrderForm from "../OrderModule/components/OrderForm";
import CompanyForm from "./components/CompanyForm";
import {OrderResponse} from "../../../Api/data/order/types";
import {getOrderDetails} from "../../../Api/data/order/client";

export const fields : ItemField<CompanyRequest>[] = [
    {
        name: "companyID",
        title: "Identifikátor",
        description: "Identifikátor",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "name",
        title: "Název",
        columnDef: {
            flex: 3
        },
    },
    {
        name: "regNumber",
        title: "IČ",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "vatNumber",
        title: "DIČ",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "description",
        title: "Popis",
        columnDef: {
            flex: 3
        },
    },
    {
        name: "telephone",
        title: "Telefon",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "email",
        title: "Email",
        columnDef: {
            flex: 2,
        },
    },
    {
        name: "website",
        title: "Web",
        columnDef: {
            flex: 2,
        },
    },
];


const CompanyModule: React.FC = () => {

    const [ data, setData ] = useState<CompanyResponse[]>([]);


    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<CompanyResponse[]>((resolve, reject) => {
            getAllCompanies().then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<OnSubmitDone>((resolve, reject) => {
            if (formValues["companyID"]) {
                // update
                // @ts-ignore
                updateCompany(formValues["companyID"], formValues as CurrencyRequest).then((response) => {
                    resolve({id: response.companyID});
                }).catch(reject)
            }
            else {
                // create
                // @ts-ignore
                addCompany(formValues as CompanyRequest).then((response) => {
                    resolve({id: response.companyID, inserted: true});
                }).catch(reject)
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteCompany(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<CompanyResponse>((resolve, reject) => {
            // const companyResponse = data.find(i => i.companyID == id);
            // if (companyResponse) {
            //     resolve(data.find(i => i.companyID == id));
            // }
            // else {
                getCompanyDetails(id).then((data) => {
                    resolve(data)
                })
            // }
        });
    }, [data]);




    return ( keycloakInitialized && keycloak?.token ? (
            <CrudModule<CompanyResponse, CompanyRequest>
                name={"Firmy"}
                list={{
                    title: "Seznam firem",
                    getRowID: (currency) => currency.companyID,
                    actionsHidden: true,
                    initialState: {
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                    },
                }}
                detail={{
                    title: "Detail firmy",
                    formTemplate: CompanyForm as any
                }}
                itemFields={fields}
                data={fetchData}
                getDetail={getDetail}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
            />
    ) : null

    );
};

export default CompanyModule;