import { onStart } from "@iolabs/app";
import { AnyAction } from "redux";
import ApiClient from "../../packages/Api/ApiClient";
import { ActionTypes } from "./action";
import { selectEvent, selectToken } from "./selector";

const onKeycloackEventMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);

    if (action.type === ActionTypes.OnEvent) {
        const event = selectEvent(getState());
        if (event === "onReady") {
            return dispatch(onStart());
        }
        if (event === "onAuthSuccess") {
            return dispatch(onStart());
        }
    }

    return resolve;
};

export default [onKeycloackEventMiddleware];
