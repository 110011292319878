import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                marginTop: theme.spacing(1),
            },
            "& svg": {
                fill: theme.palette.common.black,
            },
        },
    })
);

export default useStyles;
