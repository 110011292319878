import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            margin: theme.spacing(1, 0),
            display: "flex",
            flexDirection: "column"
        },
        buttons: {
            textAlign: "right",
        },
        gridItemContent: {
            padding: theme.spacing(1),
        },
        quickFilter: {
            float: "right",
        },
        listRoot: {
            // "& .MuiDataGrid-renderingZone": {
            //     maxHeight: "none !important"
            // },
            // "& .MuiDataGrid-cell": {
            //     lineHeight: "unset !important",
            //     maxHeight: "none !important",
            //     whiteSpace: "normal"
            // },
            // "& .MuiDataGrid-row": {
            //     maxHeight: "none !important"
            // }
        },
        fabOuter: {
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            pointerEvents: "none",
        },
        fabInner: {
            position: "fixed",
            pointerEvents: "all",
            bottom: theme.spacing(4),
            right: theme.spacing(2),
        }
    })
);

export default useStyles;
