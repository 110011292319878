import React from "react";
import {TextField} from "mui-rff";
import {TEXT_FIELD_TYPE} from "mui-rff/dist/TextField";
import useStyles from "./styles";
import clsx from "clsx";
import {TextFieldProps} from "mui-rff/src/TextField";
import Counter from "./Counter";

interface IStyledTextFieldProps extends TextFieldProps {
    submitting?: boolean,
    loading?: boolean,
    name: string,
    label: string,
    type?: TEXT_FIELD_TYPE,
    className?: string,
    readonly?: boolean,
    value?: any,
}

const StyledTextField: React.FC<IStyledTextFieldProps> = ({submitting, loading, name, label, type, className, readonly, value, ...rest}) => {
    const classes = useStyles();
    return (
        <>
            <TextField
                // required
                name={name}
                label={label}
                placeholder={label}
                type={type ? type : "text"}
                fieldProps={{
                    subscription: {
                        value: true
                    },
                }}
                fullWidth
                // margin="normal"
                variant="outlined"
                color="primary"
                className={clsx(classes.textField, className)}
                InputProps={{
                    readOnly:  readonly || submitting || loading
                }}
                {...rest}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Counter name={`field ${name}`} />
        </>
    );
}
export default StyledTextField;