import React from "react";
import { IRouteParams } from "../../CrudModule";
import { Redirect, Route, Router, Switch, useHistory, useParams, useRouteMatch } from "react-router";
import { CrudDetailTabConfiguration, ICrudDetailLoadedProps, ICrudDetailProps } from "./CrudDetail";
import useStyles from "./styles";
import { Box } from "@material-ui/core";
import {useGetPath} from "../../pathUtils";
import PanelMenu from "../PanelMenu/PanelMenu";


const CrudDetailTabs = <DT,>({ config, itemFields, data, loading, onSubmit, backLink} : ICrudDetailLoadedProps<DT> ) => {

    const history = useHistory();
    let { path, url } = useRouteMatch();
    let { itemID } = useParams<IRouteParams>();

    const classes = useStyles();

    const { getPath } = useGetPath();

    const getLink = (tab: CrudDetailTabConfiguration<DT>) => {
        return `${getPath(path, {
            itemID: itemID
        })}/${tab.slug}`;
    }

    return (
        <>
            {
                config.tabs && (
                    <>
                        <Box className={classes.panel}>
                            <PanelMenu items={config.tabs.map(t => {
                                return {
                                    link: getLink(t),
                                    title: t.title
                                }
                            })} />
                        </Box>

                        <Router history={history}>
                            <Switch>
                                <Route path={path} exact>
                                    <Redirect to={getLink(config.tabs[0])} />
                                </Route>
                                {config.tabs.map(t => (
                                    <Route path={getLink(t)} key={`route-${t.slug}`}>
                                        <Box className={classes.content}>
                                            <t.component config={config} itemFields={itemFields} data={data} onSubmit={onSubmit} backLink={backLink} />
                                        </Box>
                                    </Route>
                                ))}
                            </Switch>
                        </Router>
                    </>
                )
            }

        </>
    );
};
export default CrudDetailTabs;