import { Plugins } from "@capacitor/core";
import { LanguageProvider } from "@iolabs/language-provider";
import { IonApp, IonRouterOutlet, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import config from "../../config/config";
import DialogProvider from "../../dialogs/DialogProvider/DialogProvider";
import { Path } from "../../pages/PageRouter";
import SecurityProvider from "../../redux/keyclock/provider";
import { StoreProvider } from "../../redux/provider";
import theme from "../../theme/Theme";
import "../../theme/variables.css";
import de from "./../../translations/de.json";
import en from "./../../translations/en.json";
import cs from "./../../translations/cs.json";
import fr from "./../../translations/fr.json";
import App from "./App";
import PublicApp from "./PublicApp";
import { PrivateRoute } from "./PrivateRoute";

const { Network } = Plugins;

type NetworkStatus = {
    connected: boolean;
    connectionType: string;
};

const AppWrapper: React.FC = () => {
    const [networkStatus, setNetworkStatus] = useState<NetworkStatus>({
        connected: true,
        connectionType: "wifi",
    });

    useEffect(() => {
        // detecting connection status only in native platforms
        Network.addListener("networkStatusChange", (status) => {
            setNetworkStatus(status);
        });
    }, []);

    const renderApp = () => {
        return (
            <SecurityProvider>
                <DialogProvider>
                    <IonApp>
                        <IonReactRouter>
                            <IonRouterOutlet animated={false}>
                                <Route path="/public" component={PublicApp} />
                                <PrivateRoute component={App} />
                            </IonRouterOutlet>
                        </IonReactRouter>
                    </IonApp>
                </DialogProvider>
            </SecurityProvider>
        );
    };

    return (
        <StoreProvider>
            <ThemeProvider theme={theme}>
                <LanguageProvider
                    defaultLocale="cs"

                    messages={{
                        cs,
                        de,
                        en,
                        fr,
                    }}
                >
                    {renderApp()}
                </LanguageProvider>
            </ThemeProvider>
        </StoreProvider>
    );
};

export default AppWrapper;
