import { ActionTypes } from "./action";
import { AnyAction } from "redux";

export interface IAppModuleState {
    appState: IAppState;
}

export interface IAppState {
    pageTitle?: string;
    projectTitle?: string;
}

const initialState: Readonly<IAppState> = {};

export function reducer(state: IAppState = initialState, action: AnyAction): IAppState {
    switch (action.type) {
        case ActionTypes.OnStart: {
            return { ...state };
        }

        case ActionTypes.OnPageTitle: {
            const { pageTitle } = action.payload;
            return { ...state, pageTitle };
        }

        case ActionTypes.OnProjectTitle: {
            const { projectTitle } = action.payload;
            return { ...state, projectTitle };
        }

        default:
            return state;
    }
}
