import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        dialogTitleRoot: {
            padding: theme.spacing(1.5, 2),
        },
        dialogContentRoot: {
            padding: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(2),
            },
        },
        dialogActionsRoot: {
            padding: theme.spacing(1, 2),
            "& button": {
                marginLeft: `${theme.spacing(1)} !important`,
            },
        },
    })
);

export default useStyles;
