import ExtendableError from "es6-error";

export default class BadRequestHttpError<T> extends ExtendableError {
    protected response: T;

    constructor(message: string = "Bad request!", response: any = null) {
        super(message);
        this.response = response;
    }

    public getResponse(): T {
        return this.response;
    }
}
