import { defineMessages } from "react-intl";

export default defineMessages({
    website: {
        id: "components.footer.website",
        defaultMessage: "www.iolabs.ch",
    },
    copyright: {
        id: "components.footer.copyright",
        defaultMessage: `Copyright © {year} - ioLabs AG`,
    },
    update: {
        id: "components.footer.update",
        defaultMessage: "Latest update",
    },
});
