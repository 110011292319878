import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {
    CurrencyRequest,
    CurrencyResponse
} from "./types";

const api = new ApiClient("/Currency", config.api.baseUrl);

/**
 * Get all currencies basic information.
 * @param token
 * @param code
 */
export const getAllCurrencies = (): Promise<CurrencyResponse[]> => {
    return api
        .get(`/List`)
        .then((response: AxiosResponse<CurrencyResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Update currency information.
 * @param token
 * @param currencyID
 * @param currencyData
 */
export const updateCurrency = (currencyId: number, currencyData: CurrencyRequest): Promise<CurrencyResponse> => {
    return api
        .post(`/${currencyId}`, currencyData)
        .then((response: AxiosResponse<CurrencyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Add new currency.
 * @param token
 * @param currencyData
 */
export const addCurrency = (currencyData: CurrencyRequest): Promise<CurrencyResponse> => {
    return api
        .post(`/New`, currencyData)
        .then((response: AxiosResponse<CurrencyResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Delete currency.
 * @param token
 * @param currencyData
 */
export const deleteCurrency = (currencyId: number): Promise<void> => {
    return api
        .delete(`/${currencyId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
