import { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import {FileRequest, FileResponse} from "./types";
import {OrderTempPdf} from "../order/types";

const api = new ApiClient("/File", config.api.baseUrl);

/**
 * Get all currencies basic information.
 * @param token
 * @param code
 */
export const getAllCurrencies = (): Promise<FileResponse[]> => {
    return api
        .get(`/List`)
        .then((response: AxiosResponse<FileResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Update file information.
 * @param token
 * @param fileID
 * @param fileData
 */
export const updateFile = (fileId: number, fileData: FileRequest): Promise<FileResponse> => {
    return api
        .post(`/${fileId}`, fileData)
        .then((response: AxiosResponse<FileResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Add new file.
 * @param token
 * @param fileData
 */
export const addFile = (fileData: FileRequest): Promise<FileResponse> => {
    return api
        .post(`/New`, fileData)
        .then((response: AxiosResponse<FileResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};


/**
 * Delete file.
 * @param token
 * @param fileData
 */
export const deleteFile = (fileId: number): Promise<void> => {
    return api
        .delete(`/${fileId}`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Upload file.
 * @param token
 * @param fileData
 */
export const uploadFile = (fileData: FormData, reportProgress?: (percent: number) => void): Promise<FileResponse> => {
    return api
        .post(`/Upload`, fileData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                if (reportProgress) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    reportProgress(percentCompleted);
                }
            },
        })
        .then((response: AxiosResponse<FileResponse>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

/**
 * Download File
 * @param fileId
 */
export const downloadFile = (fileId: number): Promise<void> => {
    return api
        .get(`/${fileId}/Download`, {
            responseType: "blob",
        })
        .then((response: AxiosResponse<Blob>) => {

            const headerName = response.headers['x-download-name'];
			fileDownload(response.data, headerName);

            // var url = window.URL.createObjectURL(response.data);


            // var a = document.createElement("a");
            // var linkText = document.createTextNode("View File");
            // a.target = "_blank";
            // a.appendChild(linkText);
            // a.href = url;
            // a.click();

            // window.open(url);

            // fileDownload(response.data, headerName);

            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};