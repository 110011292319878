import { Button, DialogActions, DialogContent, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { DialogContext } from "../../../dialogs/DialogProvider/DialogProvider";
import { DF } from "../DialogFactory";
import messages from "./messages";
import useStyles from "./styles";

const WIP: React.FC = () => {
    const classes = useStyles();
    const { toggleDialog } = useContext(DialogContext);

    // translations
    const intl = useIntl();
    const transContent = intl.formatMessage({ ...messages.content });
    const transBtnClose = intl.formatMessage({ ...messages.btnClose });

    return (
        <>
            <DialogContent dividers className={classes.dialogContent}>
                <Typography variant="body1">{transContent}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" size="small" onClick={() => toggleDialog(DF.WIP)}>
                    {transBtnClose}
                </Button>
            </DialogActions>
        </>
    );
};

export default WIP;
