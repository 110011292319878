import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import {Redirect, Route} from "react-router";
import { IIcon } from "../components/Icon/IconSet";
import DashboardPage from "./DashboardPage/DashboardPage";
import IconsPage from "./IconsPage/IconsPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import { KeycloakRealmRole } from "../redux/keyclock/types";
import { useKeycloak } from "@react-keycloak/web";
import OrdersPage from "./OrdersPage/OrdersPage";

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: any;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    inDashboard?: boolean;
    children?: AppPage[];
    requiredRole?: KeycloakRealmRole;
    disabled?: boolean;
}

export enum Path {
    ROOT = "/",
    ICONS = "/icons",
    ORDERS = "/orders",
}

export const appPages: AppPage[] = [
    // {
    //     title: "Dashboard",
    //     path: Path.ROOT,
    //     icon: "chevron-left",
    //     exact: true,
    //     component: DashboardPage,
    // },
    {
        title: "Orders",
        path: Path.ORDERS,
        icon: "cube",
        exact: false,
        component: OrdersPage,
        inDashboard: true,
    },
    //
    // {
    //     title: "SIA 416 Area",
    //     path: "",
    //     icon: "dashboard-platform",
    //     exact: true,
    //     component: null,
    //     inDashboard: true,
    //     disabled: true,
    // },
    // {
    //     title: "Cost management",
    //     path: "",
    //     icon: "dashboard-money-sheet",
    //     exact: true,
    //     component: null,
    //     inDashboard: true,
    //     disabled: true,
    // },
    // {
    //     title: "Project comparison",
    //     path: "",
    //     icon: "dashboard-platform-split",
    //     exact: true,
    //     component: null,
    //     inDashboard: true,
    //     disabled: true,
    // },
    // {
    //     title: "Project management",
    //     path: "",
    //     icon: "settings",
    //     exact: true,
    //     component: null,
    //     inDashboard: true,
    //     disabled: true,
    // },
    // {
    //     title: "(Scheduling)",
    //     path: "",
    //     icon: "dashboard-gantt",
    //     exact: true,
    //     component: null,
    //     inDashboard: true,
    //     disabled: true,
    // },
];

export const hasRouteAccess = (keycloak: Keycloak.KeycloakInstance, appPage: AppPage) => {
    return !appPage.requiredRole || keycloak?.hasRealmRole(appPage.requiredRole);
}


const PageRouter: React.FC = () => {

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    return (
        <IonRouterOutlet>
            <Redirect path={Path.ROOT} exact={true} to={Path.ORDERS} />
            {keycloakInitialized && keycloak && appPages.filter(appPage => hasRouteAccess(keycloak, appPage)).map((appPage, index) => (
                <Route
                    key={index}
                    path={appPage.path}
                    exact={appPage.exact}
                    render={(props) => {
                        return <appPage.component {...props} title={appPage.title} />;
                    }}
                />
            ))}
            <Route path={Path.ICONS} exact={true} component={IconsPage} />
            <Route component={NotFoundPage} />
        </IonRouterOutlet>
    );
};

export default PageRouter;
