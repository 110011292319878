import { defineMessages } from "react-intl";

export default defineMessages({
    dialogTitle: {
        id: "components.dialog.confirmDialog.dialogTitle",
        defaultMessage: "Are you sure?",
    },
    confirmMessage: {
        id: "components.dialog.confirmDialog.confirmMessage",
        defaultMessage: "Confirm the action",
    },
    closeButton: {
        id: "components.dialog.confirmDialog.closeButton",
        defaultMessage: "Close",
    },
    confirmButton: {
        id: "components.dialog.confirmDialog.confirmButton",
        defaultMessage: "Ok",
    },
});
