import {GridValueFormatterParams} from "@mui/x-data-grid";
import {formatDate, formatDateSimple, formatDateTime, humanFileSize} from "../../../utils/Date";
import {OrderState} from "../../Api/data/order/types";

export const dateFormatter = (params: GridValueFormatterParams<Date>) => {
    if (params.value == null) {
        return '';
    }
    else {
        return formatDateSimple(params.value);
    }
}

export const dateTimeFormatter = (params: GridValueFormatterParams<Date>) => {
    if (params.value == null) {
        return '';
    }
    else {
        return formatDateTime(params.value);
    }
}

export const fileSizeFormatter = (params: GridValueFormatterParams<Date>) => {
    if (params.value == null) {
        return '';
    }
    else {
        return humanFileSize(params.value)
    }
}
export const normalizeStateLabel = (state: OrderState) => {
    switch (state) {
        case OrderState.New:
            return "";
        case OrderState.QuoteSend:
            return "Nabídka";
        case OrderState.Ordered:
            return "Objednávka";
        case OrderState.Invoiced:
            return "Faktura";
        case OrderState.Paid:
            return "Zaplaceno";
        default:
            return state;
    }
}