import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import globalMessages from "../../components/App/messages";
import Icon from "../../components/Icon/Icon";
import Page from "../Page/Page";
import { Path } from "../PageRouter";
import messages from "./messages";
import useStyles from "./styles";
import config from "../../config/config";

const NotFoundPage: React.FC = () => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...messages.headerTitle });
    const transNotFound = intl.formatMessage({ ...messages.notFound });
    const transHomepage = intl.formatMessage({ ...messages.homepage });

    return (
        <Page>
            <Helmet>
                <title>{`${config?.template?.title} - ${transHeaderTitle}`}</title>
            </Helmet>

            <Box className={classes.root}>
                <Icon name="construction" size={80} />
                <Typography variant="body1">{transNotFound}</Typography>

                <Box mt={4}>
                    <Button component={RouterLink} to={Path.ROOT} variant="contained" color="primary">
                        {transHomepage}
                    </Button>
                </Box>
            </Box>
        </Page>
    );
};

export default NotFoundPage;
