import React, { useCallback } from "react";
import { Route, Router, Switch, useHistory, useParams, useRouteMatch } from "react-router";

import {GridColDef, GridRowsProp, GridValidRowModel} from "@mui/x-data-grid";
import CrudList, { CrudListConfiguration } from "./components/CrudList/CrudList";
import CrudDetail, { CrudDetailConfiguration, ICrudDetailProps } from "./components/CrudDetail/CrudDetail";
import { TEXT_FIELD_TYPE } from "mui-rff/dist/TextField";
import { getNewPath } from "./pathUtils";
import {FormApi} from "final-form";
import { Paper } from "@material-ui/core";
import useStyles from "./styles";
import CrudderProvider, {useCrudderContext} from "./CrudderContext";

export interface OnSubmitDone {
    id: number;
    inserted?: boolean;
}

export interface CrudModuleProps<LT extends GridValidRowModel, DT> {
    name: string,
    itemFields: ItemField<DT>[],
    list: CrudListConfiguration<LT>,
    detail: CrudDetailConfiguration<DT>,
    data: () => Promise<LT[]>,
    getDetail: (id: string|number) => Promise<DT>,
    getDefaultData?: () => Promise<DT>,
    onSubmit: (values: FormData) => Promise<OnSubmitDone>,
    onDelete?: (id: number) => Promise<void>,
}

export interface FormFieldDef<DT> {
    ignore?: boolean,
    type?: TEXT_FIELD_TYPE,
    render?: (field: ItemField<DT>, classes: any, form: FormApi<DT>, readonly?: boolean) => any,
    multiline?: boolean,
}
export interface ListColumnDef extends Partial<GridColDef> {
    ignore?: boolean;
}

export interface ItemField<DT> {
    name: string,
    title: string,
    description?: string,
    columnDef?: ListColumnDef;
    formFieldDef?: FormFieldDef<DT>;

}
export interface IRouteParams {
    itemID: string;
}


export interface RouteDetailProps<DT> {
    itemFields: ItemField<DT>[],
    detail: CrudDetailConfiguration<DT>,
    getDetail?: ((id: number|string) => Promise<DT>),
    onSubmit: (values: FormData) => Promise<number>,
    backLink: string;
}

const RouteDetail = <DT,>({
                                                     itemFields,
                                                     detail,
                                                     getDetail,
                                                     onSubmit,
                                                     backLink
                                                 } : RouteDetailProps<DT>) => {
    let DetailComponent: React.FC<ICrudDetailProps<DT>> = detail.component ?? CrudDetail;
    return <DetailComponent config={detail} itemFields={itemFields} data={getDetail as ((id: number|string) => Promise<DT>)} onSubmit={onSubmit} backLink={backLink}  />
};

const RouteNew = <DT,>({
                                                     itemFields,
                                                     detail,
                                                     getDetail,
                                                     onSubmit,
                                                     backLink
                                                 } : RouteDetailProps<DT>) => {
    if (typeof detail.componentNew === "function") {
        return detail.componentNew({
            itemFields,
            config: detail,
            data: getDetail,
            onSubmit,
            backLink
        })
    }

    let DetailComponent: React.FC<ICrudDetailProps<DT>> = detail.componentNew ?? CrudDetail;
    return <DetailComponent config={detail} itemFields={itemFields} data={getDetail} onSubmit={onSubmit} backLink={backLink}  />
};

const CrudModule = <LT extends GridValidRowModel, DT>(props : CrudModuleProps<LT, DT>) => {
    return (
        <CrudderProvider>
            <CrudModuleInternal {...props} />
        </CrudderProvider>
    )
};


const CrudModuleInternal = <LT extends GridValidRowModel, DT>({
    name,
    itemFields,
    list,
    detail,
    data,
    getDetail,
    getDefaultData,
    onSubmit,
    onDelete
} : CrudModuleProps<LT, DT>) => {
    const history = useHistory();
    let { path } = useRouteMatch();
    const classes = useStyles();
    const { invalidateDetail } = useCrudderContext();

    const handleSubmit = useCallback((values: FormData) => {
        return new Promise<number>((resolve, reject) => {
            onSubmit(values).then((onSubmitDone) => {
                if (!values["submit-stay"] || onSubmitDone.inserted) {
                    // stay not wanted -> redirect
                    history.push(`${path}`);
                }
                else {
                    // invalidate
                    invalidateDetail();
                }
                resolve(onSubmitDone.id);
            }).catch(reject);
        })

    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route path={getNewPath(path)}>
                    <RouteNew<DT> itemFields={itemFields} detail={detail} getDetail={getDefaultData} onSubmit={handleSubmit} backLink={path} />
                </Route>
                <Route path={`${path}/:itemID`}>
                    <RouteDetail<DT> itemFields={itemFields} detail={detail} getDetail={getDetail} onSubmit={handleSubmit} backLink={path} />
                </Route>
                <Route path={path}>
                    <Paper className={classes.paper}>
                        <CrudList<LT> config={list} itemFields={itemFields} data={data} onDelete={onDelete} />
                    </Paper>
                </Route>
            </Switch>
        </Router>
    );
};

export default CrudModule;