import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        dialogContent: {
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
        },
    })
);

export default useStyles;
