/**
 * Feedback form values
 */
export interface IFeedbackFormValues {
    type: FeedbackType;
    subject: string;
    message: string;
}

/**
 * Feedback form errors
 */
export interface IFeedbackFormErrors {
    type?: string;
    subject?: string;
    message: string;
}

/**
 * Feedback type
 */
export enum FeedbackType {
    QUESTION = "question",
    PROBLEM = "problem",
    SUGGESTION = "suggestion",
}
