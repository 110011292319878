import {Box, Grid, Typography} from "@material-ui/core";
import {useKeycloak} from "@react-keycloak/web";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useIntl} from "react-intl";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import Page from "../Page/Page";
import pageMessages from "../messages";
import useStyles from "./styles";
import {useHeaderStyles} from "../../components/Header/Header";
import {useTheme} from "@material-ui/core/styles";
import CurrencyModule from "../../packages/Currencies/components/CurrencyModule/CurrencyModule";
import CompanyModule from "../../packages/Currencies/components/CompanyModule/CompanyModule";
import OrdersMenu from "./OrdersMenu";
import {Redirect, Route, Router, Switch, useHistory, useRouteMatch} from "react-router";
import OrderModule from "../../packages/Currencies/components/OrderModule/OrderModule";
import FileModule from "../../packages/Currencies/components/FileModule/FileModule";
import WelcomeModule from "../../packages/Currencies/components/WelcomeModule";
import SystemVariablesModule from "../../packages/Currencies/components/SystemVariablesModule/SystemVariablesModule";
import config from "../../config/config";

interface IPageSection {
    name: string;
    slug: string;
    component;
    // : string;
}

export const sections: IPageSection[] = [
    // {
    //     name: "Home",
    //     slug: "",
    //     component: WelcomeModule
    // },
    {
        name: "Nabídky",
        slug: "orders",
        component: OrderModule
    },
    {
        name: "Firmy",
        slug: "companies",
        component: CompanyModule
    },
    {
        name: "Měny",
        slug: "currencies",
        component: CurrencyModule
    },
    {
        name: "Soubory",
        slug: "files",
        component: FileModule
    },
    {
        name: "Systémové proměnné",
        slug: "system-variables",
        component: SystemVariablesModule
    },
];

const OrdersPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const history = useHistory();
    let { path } = useRouteMatch();

    const theme = useTheme();
    const [ showcases, setShowcases ] = useState<any[]>([]);

    useEffect(() => {

    }, [keycloakInitialized]);

    // translations
    const intl = useIntl();
    const transHeaderTitle = intl.formatMessage({ ...pageMessages.pageOrders });


    // useEffect(() => {
    //     setHeaderContent(
    //         <>
    //             <Typography variant="h1" component="h1" className={headerClasses.heading}>
    //                 Správa nabídek
    //             </Typography>
    //             <OrdersMenu basePath={path=='/'?'':path} />
    //         </>
    //     );
    // }, [transHeaderTitle, showcases, path]);


    return (
            <Page noPadding={true}>
                <Helmet>
                    <title>{config?.template?.title} - Správa nabídek</title>
                </Helmet>

                <Box className={classes.detailBox}>
                    <Grid container direction="row" className={classes.grid}>

                        <Router history={history}>
                            <Switch>
                                <Redirect exact={true} path={path} to={`${path}/orders`} />
                                {sections.map(section => (
                                    <Route
                                        key={`route-${section.slug}`}
                                        path={`${path}/${section.slug}`}
                                        render={(props) => {
                                            return section.component ? (
                                                <section.component {...props} title={section.name} />
                                            ): (
                                                <>
                                                    section not def
                                                </>
                                            );
                                        }}>
                                    </Route>
                                ))}
                            </Switch>
                        </Router>

                    </Grid>
                </Box>
            </Page>
    );
};

export default OrdersPage;
