import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IFeedbackSendRequestData, IFeedbackSendResponseData } from "./types";

const feedbackApi = new ApiClient("/Feedback", config.api.baseUrl);

/**
 * Sends feedback to feedback system.
 * @param token
 * @param data
 */
export const feedbackSend = (token: string, data: IFeedbackSendRequestData): Promise<IFeedbackSendResponseData> => {
    ApiClient.setAuthorizationToken(token);
    return feedbackApi
        .post(`/Send`, data)
        .then((response: AxiosResponse<IFeedbackSendResponseData>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};
