import { defineMessages } from "react-intl";

export default defineMessages({
    title: {
        id: "components.wipDialog.title",
        defaultMessage: "In progress",
    },
    content: {
        id: "components.wipDialog.content",
        defaultMessage: "This app is under development. This functionality is enabled in the full version.",
    },
    btnClose: {
        id: "components.wipDialog.btnClose",
        defaultMessage: "Close",
    },
});
