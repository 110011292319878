import { addNotification } from "@iolabs/notifier";
import { AnyAction } from "redux";
import { feedbackSend } from "../../packages/Api/data/feedback/client";
import { ActionTypes, onFeedbackSendDone } from "./action";

const onFeedbackMiddleware = ({ dispatch }) => (next) => (action: AnyAction) => {
    const resolve = next(action);

    // feedback send
    if (action.type === ActionTypes.OnFeedbackSend) {
        const { token, data, messageSuccess, messageError } = action.payload;

        feedbackSend(token, data)
            .then((response) => {
                dispatch(
                    onFeedbackSendDone({
                        response,
                    })
                );
                dispatch(
                    addNotification({
                        notification: {
                            variant: "success",
                            message: messageSuccess,
                        },
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    addNotification({
                        notification: {
                            variant: "error",
                            message: messageError,
                        },
                    })
                );
            });
    }

    return resolve;
};

export default [onFeedbackMiddleware];
