import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    createStyles,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Theme,
    useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import { appPages } from "../../pages/PageRouter";
import { isMenuItemActive } from "../../utils/Menu";
import { defaultDrawerWidth } from "../Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.text.secondary,
            width: `${defaultDrawerWidth}px`,
            paddingTop: theme.spacing(2),
        },
        listItemIcon: {
            minWidth: "38px",
        },
        menuIcon: {
            color: theme.palette.text.secondary,
        },
        menuIconActive: {
            color: theme.palette.secondary.main,
        },
        menuText: {
            paddingLeft: theme.spacing(1),
            whiteSpace: "nowrap",
            "& span": {
                lineHeight: "1.2rem",
            },
        },
        listItem: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
        },
        collapseButton: {
            color: theme.palette.text.secondary,
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
        active: {
            color: theme.palette.common.white,
        },
        divider: {
            backgroundColor: theme.palette.primary.light,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
    })
);

interface IDrawerProps extends React.HTMLAttributes<HTMLElement> {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

const Menu: React.FC<IDrawerProps> = (props: IDrawerProps) => {
    const { handleDrawerClose } = props;

    const classes = useStyles();
    const theme = useTheme();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const location = useLocation();
    const history = useHistory();

    const handleRouter = (route: string) => {
        history.push(route);
        handleDrawerClose();
    };

    return (
        <List component="nav" aria-labelledby="menu" className={classes.root}>
            {appPages
                .filter((page) => page?.inMenu)
                .map((page, index) => (
                    <ListItem
                        key={index}
                        button
                        onClick={() => (page?.wip ? setGlobalDialogOpen(true) : handleRouter(page.path))}
                        className={clsx(classes.listItem, {
                            [classes.active]: isMenuItemActive(location.pathname, page.path),
                        })}
                        disabled={page?.wip}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <Icon
                                name={page.icon}
                                fill={
                                    isMenuItemActive(location.pathname, page.path)
                                        ? theme.palette.secondary.main
                                        : theme.palette.text.secondary
                                }
                                size={18}
                                className={classes.menuIcon}
                            />
                        </ListItemIcon>
                        <ListItemText primary={page.title} className={classes.menuText} />
                    </ListItem>
                ))}
        </List>
    );
};

export default Menu;
