import { useKeycloak } from '@react-keycloak/web';
import React, {useEffect, useState} from "react";
import { Route } from 'react-router-dom';
import ApiClient from "../../packages/Api/ApiClient";

export function PrivateRoute({ component: Component, ...rest }) {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [tokenSetToApiClient, setTokenSetToApiClient] = useState<boolean>(false);

    useEffect(() => {
        if (keycloakInitialized && keycloak?.authenticated == false) {
            keycloak.login();
        }
        else if (keycloak?.authenticated && keycloak.token) {
            ApiClient.setAuthorizationToken(keycloak.token);
            setTokenSetToApiClient(true);
        }
    }, [keycloakInitialized, keycloak?.token]);


    return (
        <Route
            {...rest}
            render={props => keycloakInitialized && keycloak?.authenticated == true && tokenSetToApiClient ? <Component {...props} /> : ""}
        />
    )
}