import React, {useEffect} from "react";
import {Box, Button, LinearProgress, Typography} from "@material-ui/core";
import {DropzoneInputProps} from "react-dropzone";


interface IFileDropzoneProps {
    onChange: (files: number[]) => void,
    value: any
    doneFiles?: number[];
    processingFiles?: {[key: string]: IProcessingFileProps};
    open?: any;
}


interface IProcessingFileProps {
    name: string,
    progress: number,
}

const FileDropZone: React.FC<IFileDropzoneProps> = ({ onChange, value, doneFiles, processingFiles, open}) => {

    useEffect(() => {
        if (doneFiles) {
            onChange(doneFiles);
        }
    }, [doneFiles]);

    return (
        <>
            <section>
                <Box mb={1}>
                    {
                        processingFiles && Object.values(processingFiles)?.length ? (
                            Object.values(processingFiles)?.map((f, index) => (
                                <Box key={`file-${index}`}>
                                    <Typography>{f.name}</Typography>
                                    <LinearProgress variant="determinate" value={f.progress} />
                                </Box>
                            ))
                        ) : (
                            <p>
                                Přetáhněte soubory sem, nebo klikněte na tlačítko níže
                            </p>
                        )
                    }
                </Box>
            </section>
            <Button variant={"contained"} onClick={open}>Přidat přílohy</Button>
        </>
    );
}
export default FileDropZone;