import React, {useState} from "react";
import {Link} from "react-router-dom";
import useStyles from "./styles";
import {Box, Typography} from "@mui/material";
import {Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import {Switches} from "mui-rff";
import {Button, CircularProgress, Icon} from "@material-ui/core";
import {ICrudDetailFormProps} from "./CrudDetail";
import {Alert, AlertTitle} from "@material-ui/lab";
import {ItemField} from "../../CrudModule";
import CrudDetailAutoFields from "./CrudDetailAutoFields";
import clsx from "clsx";
import {ArrowBack, RotateLeft} from "@material-ui/icons";
import Log from "./Log";

export interface ISubmitError {
    title: string,
    details: string,
}

export interface IFormTemplate<DT> {
    // loading?: boolean,
    // submitting?: boolean,
    // readonly?: boolean,
    // form: FormApi,
    data: DT;
    itemFields: ItemField<DT>[],
}

const CrudDetailForm = <DT,>({ config, itemFields, data, loading, onSubmit, backLink} : ICrudDetailFormProps<DT> ) => {
    const [ submitRunning, setSubmitRunning ] = useState(false);
    const [ submitError, setSubmitError ] = useState<ISubmitError>();

    const classes = useStyles();


    function handleSubmit(values: FormData, event: any) {
        return new Promise<number>((resolve, reject) => {
            setSubmitRunning(true);
            onSubmit(values)
                .then((id) => {
                    resolve(id);
                })
                .catch((e) => {
                    setSubmitError({
                        title: "Chyba při ukládání",
                        details: e.response?.data?.ErrorMessage
                    });
                    reject(e);
                })
                .finally(() => {
                    setSubmitRunning(false);
                })
            ;
        });
    }

    const handleReset = (form?: any) => {
        form?.reset();
        setSubmitRunning(false);
        setSubmitError(undefined);
    }

    // yes, this can even be async!
    async function validate(values: FormData) {
        // if (!values.hello) {
        //     return { hello: 'Saying hello is nice.' };
        // }
        return;
    }

    const getInitialFormValue = (): any => {
        return {
            ...data,
            ["submit-stay"]: false
        };
    }


    return (
        <>

            <Typography>{config.title}</Typography>
            <Form
                onSubmit={handleSubmit}
                // validate={validate}
                // validateOnBlur
                initialValues={getInitialFormValue()}
                className={classes.form}
                mutators={{
                    ...arrayMutators,
                    ...config.mutators
                }}
                decorators={config.decorators}
                subscription={{
                    submitting: true,
                    pristine: true,
                }}
                render={({ handleSubmit, values, submitting, pristine, form}) => (
                    <form onSubmit={handleSubmit} noValidate className={clsx(classes.formHtml, {[classes.submitting]: submitRunning})}>
                        <Log value={{submitting, pristine, loading}} />
                        <Box display={"flex"} flexDirection={"column"} className={classes.formWrapper}>
                            <Box className={classes.formContent}>
                                {config.formTemplate ? (
                                    <>
                                        <config.formTemplate key={"form-template"} itemFields={itemFields} data={data} />
                                    </>
                                ) : (
                                    <>
                                        <CrudDetailAutoFields itemFields={itemFields as any} form={form} loading={loading} submitting={submitting} />
                                    </>
                                )}
                                {submitError && (
                                    <Alert className={classes.submitError} severity={"error"}>
                                        <AlertTitle>{submitError.title}</AlertTitle>
                                        {submitError.details}
                                    </Alert>
                                )}
                            </Box>

                            <Box className={classes.formButtons}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        id={"save"}
                                        name={"save"}
                                        disabled={submitting || pristine  || loading}
                                        endIcon={submitting ? <CircularProgress size={22} color={"inherit"} /> : <Icon>save</Icon>}
                                        style={{
                                            marginRight: "1.5rem",
                                        }}
                                    >
                                        Uložit
                                    </Button>
                                    <Box className={classes.stayBtn}>
                                        <Switches
                                            name="submit-stay"
                                            data={{label: 'Zůstat na stránce', value: false}}
                                        />
                                    </Box>
                                    {config.showReset && (
                                        <Button
                                            type="reset"
                                            variant="contained"
                                            color="secondary"
                                            disabled={submitting || pristine || loading}
                                            onClick={() => handleReset(form)}
                                            endIcon={<RotateLeft />}
                                        >
                                            Reset
                                        </Button>
                                    )}

                                    <Button
                                        type="button"
                                        variant="text"
                                        color="primary"
                                        disabled={submitting}
                                        component={Link}
                                        to={backLink}
                                        style={{
                                            marginLeft: "1.5rem",
                                        }}
                                        endIcon={<ArrowBack />}
                                    >
                                        Zpět na přehled
                                    </Button>
                                </Box>
                            </Box>


                        </Box>
                    </form>
                )}
            >
            </Form>
        </>
    );
};
export default CrudDetailForm;