import { createStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Icon from "../../components/Icon/Icon";
import Page from "../Page/Page";

const useStyles = makeStyles(() =>
    createStyles({
        ul: {
            listStyle: "none",
            paddingBottom: "4rem",
        },
        li: {
            display: "flex",
            alignItems: "center",
        },
        span: {
            display: "inline-block",
            width: "150px",
        },
        icon: {
            fill: "#000",
        },
    })
);

const IconsPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page>
            <ul className={classes.ul}>
                <li>
                    <h3>Icons list:</h3>
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>attachments</span>
                    <Icon name="attachments" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>arrow-alt-to-bottom</span>
                    <Icon name="arrow-alt-to-bottom" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>arrow-from-left</span>
                    <Icon name="arrow-from-left" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>batch-update</span>
                    <Icon name="batch-update" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>blade</span>
                    <Icon name="blade" size={40} className={classes.icon} />
                    <Icon name="blade-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>calendar</span>
                    <Icon name="calendar" size={40} className={classes.icon} />
                    <Icon name="calendar-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>camera</span>
                    <Icon name="camera" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chart-pie</span>
                    <Icon name="chart-pie" size={40} className={classes.icon} />
                    <Icon name="chart-pie-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chart</span>
                    <Icon name="chart" size={40} className={classes.icon} />
                    <Icon name="chart-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>check</span>
                    <Icon name="check" size={40} className={classes.icon} />
                    <Icon name="check-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chevron-left</span>
                    <Icon name="chevron-left" size={40} className={classes.icon} />
                    <Icon name="chevron-left-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chevron-left-circle</span>
                    <Icon name="chevron-left-circle" size={40} className={classes.icon} />
                    <Icon name="chevron-right-circle" size={40} className={classes.icon} />
                    <Icon name="chevron-up-circle" size={40} className={classes.icon} />
                    <Icon name="chevron-down-circle" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chevron-light-left</span>
                    <Icon name="chevron-light-left" size={40} className={classes.icon} />
                    <Icon name="chevron-light-right" size={40} className={classes.icon} />
                    <Icon name="chevron-light-up" size={40} className={classes.icon} />
                    <Icon name="chevron-light-down" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chevron-left</span>
                    <Icon name="chevron-left" size={40} className={classes.icon} />
                    <Icon name="chevron-left-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>chevron-up</span>
                    <Icon name="chevron-up" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>clock</span>
                    <Icon name="clock" size={40} className={classes.icon} />
                    <Icon name="clock-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>clone</span>
                    <Icon name="clone" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>cloud-upload-alt</span>
                    <Icon name="cloud-upload-alt" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>close</span>
                    <Icon name="close" size={40} className={classes.icon} />
                    <Icon name="close-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>cloud-download</span>
                    <Icon name="cloud-download" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>cog</span>
                    <Icon name="cog" size={40} className={classes.icon} />
                    <Icon name="cog-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>comments</span>
                    <Icon name="comments" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>comment</span>
                    <Icon name="comment" size={40} className={classes.icon} />
                    <Icon name="comment-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>comment-edit</span>
                    <Icon name="comment-edit" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>construction</span>
                    <Icon name="construction" size={40} className={classes.icon} />
                    <Icon name="construction-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>copy</span>
                    <Icon name="copy" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>cube</span>
                    <Icon name="cube" size={40} className={classes.icon} />
                    <Icon name="cube-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>dollar</span>
                    <Icon name="dollar" size={40} className={classes.icon} />
                    <Icon name="dollar-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>ellipsis-h</span>
                    <Icon name="ellipsis-h-alt" size={40} className={classes.icon} />
                    <Icon name="ellipsis-h" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>ellipsis-v</span>
                    <Icon name="ellipsis-v-alt" size={40} className={classes.icon} />
                    <Icon name="ellipsis-v" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>exclamation-triangle</span>
                    <Icon name="exclamation-triangle" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>eye</span>
                    <Icon name="eye" size={40} className={classes.icon} />
                    <Icon name="eye-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>file</span>
                    <Icon name="file" size={40} className={classes.icon} />
                    <Icon name="file-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>file-pdf</span>
                    <Icon name="file-pdf" size={40} className={classes.icon} />
                    <Icon name="file-pdf-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>file-image</span>
                    <Icon name="file-image" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>file-upload</span>
                    <Icon name="file-upload" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>file-x</span>
                    <Icon name="file-x" size={40} className={classes.icon} />
                    <Icon name="file-x-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>folder</span>
                    <Icon name="folder" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>folder-plus</span>
                    <Icon name="folder-plus" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>filter</span>
                    <Icon name="filter" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>folder-open</span>
                    <Icon name="folder-open" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>gaeb</span>
                    <Icon name="gaeb" size={40} className={classes.icon} />
                    <Icon name="gaeb-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>grip-horizontal</span>
                    <Icon name="grip-horizontal" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>grip-lines</span>
                    <Icon name="grip-lines" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>hand</span>
                    <Icon name="hand" size={40} className={classes.icon} />
                    <Icon name="hand-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>history</span>
                    <Icon name="history" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>info</span>
                    <Icon name="info" size={40} className={classes.icon} />
                    <Icon name="info-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>issues</span>
                    <Icon name="issues" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>linked-document</span>
                    <Icon name="linked-document" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>list</span>
                    <Icon name="list" size={40} className={classes.icon} />
                    <Icon name="list-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>list-border</span>
                    <Icon name="list-border" size={40} className={classes.icon} />
                    <Icon name="list-border-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>lock</span>
                    <Icon name="lock" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>maze</span>
                    <Icon name="maze" size={40} className={classes.icon} />
                    <Icon name="maze-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>no-way</span>
                    <Icon name="no-way" size={40} className={classes.icon} />
                    <Icon name="no-way-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>pencil-solid</span>
                    <Icon name="pencil-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>people</span>
                    <Icon name="people" size={40} className={classes.icon} />
                    <Icon name="people-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>public</span>
                    <Icon name="public" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>qr-code</span>
                    <Icon name="qr-code" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>private</span>
                    <Icon name="private" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>cloud-download</span>
                    <Icon name="cloud-download" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>person</span>
                    <Icon name="person" size={40} className={classes.icon} />
                    <Icon name="person-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>plot</span>
                    <Icon name="plot" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>plus-circle</span>
                    <Icon name="plus-circle" size={40} className={classes.icon} />
                    <Icon name="plus-circle-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>print</span>
                    <Icon name="print" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>rfi</span>
                    <Icon name="rfi" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>ruler</span>
                    <Icon name="ruler" size={40} className={classes.icon} />
                    <Icon name="ruler-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>share-alt</span>
                    <Icon name="share-alt" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>sign-out-alt</span>
                    <Icon name="sign-out-alt" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>settings</span>
                    <Icon name="settings" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>splitview-2d</span>
                    <Icon name="splitview-2d" size={40} className={classes.icon} />
                    <Icon name="splitview-both" size={40} className={classes.icon} />
                    <Icon name="splitview-3d" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>target</span>
                    <Icon name="target" size={40} className={classes.icon} />
                    <Icon name="target-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>times</span>
                    <Icon name="times" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>trash-alt</span>
                    <Icon name="trash-alt" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>triangle-left</span>
                    <Icon name="triangle-left" size={40} className={classes.icon} />
                    <Icon name="triangle-right" size={40} className={classes.icon} />
                    <Icon name="triangle-up" size={40} className={classes.icon} />
                    <Icon name="triangle-down" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>triangle-left-solid</span>
                    <Icon name="triangle-left-solid" size={40} className={classes.icon} />
                    <Icon name="triangle-right-solid" size={40} className={classes.icon} />
                    <Icon name="triangle-up-solid" size={40} className={classes.icon} />
                    <Icon name="triangle-down-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>version</span>
                    <Icon name="version" size={40} className={classes.icon} />
                    <Icon name="version-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>weight</span>
                    <Icon name="weight" size={40} className={classes.icon} />
                    <Icon name="weight-solid" size={40} className={classes.icon} />
                </li>
                <li className={classes.li}>
                    <span className={classes.span}>window-restore</span>
                    <Icon name="window-restore" size={40} className={classes.icon} />
                    <Icon name="window-restore-solid" size={40} className={classes.icon} />
                </li>
            </ul>
        </Page>
    );
};

export default IconsPage;
