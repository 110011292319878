import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultSliderWidth = 400;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            position: "relative",
            width: "100%",
            flexWrap: "nowrap",
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
        gridSlide: {
            width: 0,
            visibility: "hidden",
        },
        gridSlideShift: {
            visibility: "visible",
            width: defaultSliderWidth,
            minWidth: defaultSliderWidth,
            maxWidth: defaultSliderWidth,
        },
        gridItem: {
            width: "100%",
        },
        gridItemShift: {
            width: "100%",
            maxWidth: `calc(100% - ${defaultSliderWidth}px)`,
        },
        detailBox: {
            height: "100%",
            overflow: "auto",
            "& .MuiCircularProgress-root": {
                position: "absolute",
                left: "calc(50% - 20px)",
                top: "calc(50% - 20px)",
            },
        },
        dashboardBox: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            flex: 1,
            height: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
            },
        },
        dashboardItem: {
            height: "300px",
            width: "200px",
            margin: theme.spacing(1),
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            "&:last-child": {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up("md")]: {
                height: "400px",
                width: "300px",
                margin: theme.spacing(2),
                "&:last-child": {
                    marginBottom: theme.spacing(2),
                },
            },
        },
        menu: {
            marginLeft: theme.spacing(4),
            '& > *': {
                color: theme.palette.common.white,
                margin: theme.spacing(1),
            },
        }
    })
);

export default useStyles;
