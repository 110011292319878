import React, {useCallback, useState} from "react";
import CrudModule, {ItemField, OnSubmitDone} from "../../../Crudder/CrudModule";
import {CurrencyRequest, CurrencyResponse} from "../../../Api/data/currency/types";
import {addCurrency, deleteCurrency, getAllCurrencies, updateCurrency} from "../../../Api/data/currency/client";
import {useKeycloak} from "@react-keycloak/web";
import {OrderRequest, OrderResponse, OrderState, RealizationPlace} from "../../../Api/data/order/types";
import {
    addOrder,
    deleteOrder,
    getAllOrders,
    getInitialData,
    getOrderDetails,
    updateOrder
} from "../../../Api/data/order/client";
import {TextField} from "mui-rff";
import {Box, Grid, MenuItem, Typography} from "@material-ui/core";
import InputSelect from "../../../Crudder/components/InputSelect";
import {getAllCompanies} from "../../../Api/data/company/client";
import {CompanyResponse} from "../../../Api/data/company/types";
import OrderItemsFormPart from "./components/OrderItemsFormPart";
import OrderForm from "./components/OrderForm";
import {GridColDef, GridFilterItem, GridValueFormatterParams} from "@mui/x-data-grid";
import {formatDate, formatNumber, formatNumberThousands} from "../../../../utils/Date";
import {dateFormatter, normalizeStateLabel} from "../../../Crudder/utils/utils";
import createDecorator from "final-form-calculate";
import {getIn} from "final-form";
import CrudderProvider, {useCrudderContext} from "../../../Crudder/CrudderContext";

// Create Decorator
const decorator = createDecorator(
    // Calculations:
    {
        field: /items\[\d+\]\.price/,
        updates: (value, name, allValues) => {

            // const toField = name.replace('price', 'inOrderCurrency')

            // const toValue = getIn(allValues, toField);

            const total = (allValues?.["items"] || [])
                .map(i => i.price ?? 0)
                .reduce(
                    (sum, value) => sum + parseInt(value),
                    0
                );


            return {
                // [toField]: formatNumber(value / exchangeRate),
                total: total,
                // totalInOrderCurrency: formatNumber(total / exchangeRate),
            }
        }
    },
    {
        field: "currency",
        updates: (value, name, allValues) => {

            // const toField = name.replace('price', 'inOrderCurrency')


            const updates = {}


            return updates;
        }

    }
);

const fields : ItemField<any>[] = [
    {
        name: "orderID",
        title: "ID",
        description: "ID",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "number",
        title: "Číslo",
        columnDef: {
            flex: 0,
            hide: true,
        },
    },
    {
        name: "date",
        title: "Datum",
        columnDef: {
            flex: 0,
            type: "date",
            valueFormatter: dateFormatter
        },
        formFieldDef: {
            type: "date"
        }
    },
    {
        name: "company",
        title: "Firma",
        columnDef: {
            flex: 2,
            minWidth: 250,
            valueGetter: (cell) => {
                return cell.value?.name
            },
            // valueFormatter: (params) => {
            //     return params.value.name;
            // }
        },
    },
    {
        name: "total",
        title: "Cena",
        columnDef: {
            flex: 0,
            type: "number",
            renderCell: ({ value }) => formatNumberThousands(formatNumber(value)),
            valueFormatter: ({ value }) => value,
        },
        // formFieldDef: {
        //     type: "date"
        // }
    },
    {
        name: "currency",
        title: "Měna",
        columnDef: {
            flex: 0,
            hide: true,
            valueGetter: (params) => {
                return params.value?.alpha3Code;
            }
        },
    },
    {
        name: "items",
        title: "Položky",
        columnDef: {
            flex: 3,
            minWidth: 300,
            valueGetter: (cell) => {
                return cell.value?.map(item => `${item.title} ${item.description}`)?.join(" ")
            },
            renderCell:(cell) => {
                return <Grid container direction={"row"}>
                    {cell.row.items?.map(item => (
                        <>
                            <Grid item xs={12}>
                                {item.title}
                            </Grid>
                            <Grid item xs={12}>
                                &nbsp;-&nbsp;{item.description}
                            </Grid>
                        </>
                    ))}
                </Grid>
            },
        },
        formFieldDef: {
            render: (field, classes, form, readonly) => (
                <OrderItemsFormPart field={field} readonly={readonly} form={form} />
            )
        }
    },
    {
        name: "note",
        title: "Poznámka",
        columnDef: {
            minWidth: 250,
            flex: 3,
        },
    },
    {
        name: "orderState",
        title: "Stav",
        columnDef: {
            flex: 0,
            valueGetter: (params) => {
                return normalizeStateLabel(params.value);
            },
            renderCell: (cell) => (
                <Box style={{fontWeight: cell.value == OrderState.Paid?'bold':'normal'}}>{normalizeStateLabel(cell.value)}</Box>
            )
        },
    },
    {
        name: "invoiceNumber",
        title: "Faktura",
        columnDef: {
            flex: 0,
            type: "number",
        },
    },
    {
        name: "quoteDate",
        title: "Datum nabídky",
        columnDef: {
            flex: 0,
            type: "date",
            hide: true,
            valueFormatter: dateFormatter,
        },
        formFieldDef: {
            type: "date"
        }
    },

    {
        name: "companyID",
        title: "ID firmy",
        columnDef: {
            flex: 2,
            hide: true,
            ignore: true,
        },
        formFieldDef: {
            render: (field, classes, form, readonly) => (
                <InputSelect field={field} readonly={readonly} itemsGetter={async (search) => {
                    const companies: CompanyResponse[] = await getAllCompanies();
                    return companies.map(c => (
                        <MenuItem value={c.companyID}>{c.name}</MenuItem>
                    ))
                }} />
            )
        }
    },
    {
        name: "realizationPlace",
        title: "INT/EXT",
        columnDef: {
            flex: 0,
            hide: true,
            valueFormatter: (params) => {
                return params.value == RealizationPlace.Internally ? "INT" : "EXT";
            }
        },
    },
    {
        name: "currencyID",
        title: "ID Měny",
        columnDef: {
            flex: 1,
            hide: true,
            ignore: true,
        },
        formFieldDef: {
            render: (field, classes, form, readonly) => (
                <InputSelect field={field} readonly={readonly} itemsGetter={async (search) => {
                    const currencies: CurrencyResponse[] = await getAllCurrencies();
                    return currencies.map(c => (
                        <MenuItem value={c.currencyID}>{c.name}</MenuItem>
                    ))
                }} />
            )
        }
    },
    {
        name: "exchangeRate",
        title: "Kurz",
        columnDef: {
            flex: 1,
            hide: true,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
];

const OrderModule: React.FC = () => {
    return (
        // <CrudderProvider>
            <OrderModuleInner />
        // </CrudderProvider>
        )
}

const OrderModuleInner: React.FC = () => {

    const [ data, setData ] = useState<OrderResponse[]>([]);

    // const { detailResponse,  } = useOrderContext();



    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<OrderResponse[]>((resolve, reject) => {
            getAllOrders().then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<OnSubmitDone>((resolve, reject) => {
            if (formValues["orderID"]) {
                // update
                // @ts-ignore
                updateOrder(formValues["orderID"], formValues as OrderRequest).then((response) => {
                    resolve({id: response.orderID});
                })
            }
            else {
                // create
                // @ts-ignore
                addOrder(formValues as OrderRequest).then((response) => {
                    resolve({id: response.orderID, inserted: true });
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteOrder(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<OrderResponse>((resolve, reject) => {
            getOrderDetails(id).then((data) => {
                resolve(data)
            })
        });
    }, [data]);

    const getDefaultData = useCallback(() => {
        return new Promise<OrderResponse>((resolve, reject) => {
            getInitialData().then((data) => {
                resolve(data)
            })
        });
    }, [data]);


    return ( keycloakInitialized && keycloak?.token ? (
            <CrudModule<OrderResponse, OrderRequest>
                name={"Nabídky"}
                list={{
                    title: "Seznam nabídek",
                    getRowID: (currency) => currency.orderID,
                    hideHeader: true,
                    initialState: {
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                    },
                    actionsHidden: true,
                }}
                detail={{
                    title: "Detail nabídky",
                    formTemplate: OrderForm as any,
                    decorators: [decorator as any],
                    mutators: {
                        setCurrency: ([ currency ], state, utils) => {
                            utils.changeValue(state, 'currency', () => currency)
                        },
                        setCompany: ([ company ], state, utils) => {
                            utils.changeValue(state, 'company', () => company);
                            // @ts-ignore
                            const currency = state.formState.values.currencyID;
                            if (state.formState.initialValues?.["currencyID"] == currency && company?.currencyID) {
                                // currency not set yet
                                utils.changeValue(state, 'currencyID', () => company?.currencyID);
                            }
                        },
                    }
                }}
                itemFields={fields}
                data={fetchData}
                getDetail={getDetail as any}
                getDefaultData={getDefaultData as any}
                onDelete={handleDelete}
                onSubmit={handleSubmit}
            />
    ) : null

    );
};

export default OrderModule;