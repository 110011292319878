import { addNotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { useDispatch } from "react-redux";
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import {
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    TextField,
    useMediaQuery
} from "@material-ui/core";
import {OrderResponse, SendMailDto} from "../../../../Api/data/order/types";
import {useTheme} from "@material-ui/core/styles";
import useStyles from "./styles";
import {Close} from "@material-ui/icons";
import {Alert, AlertTitle, Autocomplete} from "@material-ui/lab";
import {Field, Form} from "react-final-form";
import {ISubmitError} from "../../../../Crudder/components/CrudDetail/CrudDetailForm";
import StyledTextField from "../../../../Crudder/components/CrudDetail/StyledTextField";
// import {Autocomplete} from "mui-rff";
import {formatDate} from "../../../../../utils/Date";
import {useKeycloak} from "@react-keycloak/web";
import {KeycloakProfile} from "keycloak-js";
import OrderFileList from "./OrderFileList";
import {SelectMode} from "../../../../Crudder/components/CrudList/CrudList";
import {getSendEmailInitialData, sendEmail} from "../../../../Api/data/order/client";
import {useCrudderContext} from "../../../../Crudder/CrudderContext";
import {useHistory, useRouteMatch} from "react-router";
import clsx from "clsx";
import RecipientsAutocomplete from "./RecipientsAutocomplete";

interface IOrderSendMailProps {
    order: OrderResponse,
    onClose?: () => void,
    selectedAttachments?: number[],
}

const OrderSendMail: React.FC<IOrderSendMailProps> = ({ order, onClose, selectedAttachments}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const { invalidateDetail } = useCrudderContext();

    const { keycloak } = useKeycloak();
    const dispatch = useDispatch<DispatchAction>();

    const [ initialData, setInitialData ] = useState<SendMailDto>();

    const [ loading, setLoading ] = useState(false);
    const [ submitRunning, setSubmitRunning ] = useState(false);
    const [ submitError, setSubmitError ] = useState<ISubmitError>();
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);

    const [inputValue, setInputValue] = React.useState("");

    let { url } = useRouteMatch();
    const history = useHistory();

    const handleClose = () => {
        if(onClose) {
            onClose();
        }
    }

    const handleReset = (form?: any) => {
        form?.reset();
        setSubmitRunning(false);
        setSubmitError(undefined);
    }

    const handleSubmit = (values: FormData) => {
        return new Promise<void>((resolve, reject) => {
            setSubmitRunning(true);
            // @ts-ignore
            sendEmail(order.orderID, values as SendMailDto)
                .then(response => {
                    // todo trigger reload
                    invalidateDetail();
                    dispatch(
                        addNotification({
                            notification: {
                                variant: "success",
                                message: "Email byl úspěšně odeslán",
                            },
                        })
                    );
                    resolve();
                    if(onClose) {
                        onClose();
                    }
                    history.push(url.replace(/\/[\d]+$/i, ""));
                })
                .catch(error => {
                    setSubmitError(error);
                    dispatch(
                        addNotification({
                            notification: {
                                variant: "error",
                                message: "Email se nepodařilo odeslat"
                            },
                        })
                    );
                    console.error("Error sending", error);
                    reject();
                })
                .finally(() => {
                    setSubmitRunning(false);
                })
        });
    }

    useEffect(() => {
        if (keycloak) {
            keycloak?.loadUserProfile().then((data) => {
                setUserData(data as KeycloakProfile);
            })
        }

    }, [keycloak]);



    // yes, this can even be async!
    async function validate(values: FormData) {
        console.log("validating send", values);
        // if (!values.hello) {
        //     return { hello: 'Saying hello is nice.' };
        // }
        return {};
    }


    useEffect(() => {
        setLoading(true);
        getSendEmailInitialData(order.orderID)
            .then((response) => {
                setInitialData({
                    ...response,
                    attachments: selectedAttachments
                });
            })
            .catch((e) => {
                console.error("Unable to load email default", e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [order.orderID, selectedAttachments]);

    const getRecipientsOptions = () => {
        let recipients = [order.company?.email];

        const cps = order?.company?.contactPersons?.map((cp) => cp.email);

        if (cps) {
            recipients = [...recipients, ...cps];
        }

        return recipients.filter(e => !!e);
    }

    // @ts-ignore
    return userData && (
            <Dialog

                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                fullScreen={false}
                maxWidth={false}
                scroll={"paper"}
            >
                <Form
                    onSubmit={handleSubmit}
                    validate={validate}
                    initialValues={initialData}
                    className={classes.sendMailForm}
                    mutators={{
                    }}
                    render={({ handleSubmit, values, submitting, pristine, form}) => (
                        <form onSubmit={handleSubmit} noValidate className={clsx({[classes.submitting]: submitRunning}, classes.sendMailForm)}>
                            <DialogTitle id="customized-dialog-title">
                                Odeslat email
                                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers className={classes.sendMailDialogContent}>

                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <StyledTextField
                                                name={"subject"}
                                                label={"Předmět"}
                                                loading={loading}
                                                submitting={submitting}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>

                                            <Field name="recipients">
                                                {props => (
                                                    <RecipientsAutocomplete props={props} options={getRecipientsOptions()} />
                                                )
                                                }
                                            </Field>

                                        </Grid>

                                        <Grid item xs={12}>
                                            <StyledTextField
                                                name={"body"}
                                                label={"Text"}
                                                multiline={true}
                                                rows={11}
                                                loading={loading}
                                                submitting={submitting}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Field name="attachments">
                                                {props => (
                                                    <OrderFileList
                                                        key={`files-state-${1}`}
                                                        orderID={order.orderID}
                                                        crudListProps={{
                                                            selectMode: SelectMode.MULTIPLE,
                                                            density: "compact",
                                                            onSelectionChange: props.input.onChange,
                                                            selection: props.input.value
                                                        }}
                                                    />
                                                )}
                                            </Field>

                                        </Grid>

                                        {submitError && (
                                            <Grid item xs={12}>
                                                <Alert className={classes.submitError} severity={"error"}>
                                                    <AlertTitle>{submitError.title}</AlertTitle>
                                                    {submitError.details}
                                                </Alert>
                                            </Grid>
                                        )}
                                    </Grid>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    // disabled={submitting || pristine  || loading}
                                    endIcon={submitting ? <CircularProgress size={22} color={"inherit"} /> : <Icon>send</Icon>}
                                >
                                    Odeslat
                                </Button>
                                <div style={{flex: '1 0 0'}} />
                                <Button
                                    type="reset"
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    disabled={submitting || pristine || loading}
                                    onClick={() => handleReset(form)}
                                    style={{
                                        marginLeft: "1.5rem",
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button onClick={handleClose} color="secondary" variant={"contained"}>
                                    Storno
                                </Button>
                            </DialogActions>
                    </form>
                )}
            >
            </Form>

        </Dialog>
    );
}
export default OrderSendMail;