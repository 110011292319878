import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { DialogContext } from "../../dialogs/DialogProvider/DialogProvider";
import feedbackMessages from "../DialogFactory/Feedback/messages";
import wipMessages from "../DialogFactory/WIP/messages";
import Feedback from "./Feedback/Feedback";
import WIP from "./WIP/WIP";

/**
 * Dialog factory
 * - define all dialogs name here
 */
export enum DF {
    CONFIRM_DIALOG = "CONFIRM_DIALOG",
    CREATE_PRESET = "CREATE_PRESET",
    PRESET_MANAGER = "PRESET_MANAGER",
    RELOAD_CACHE = "RELOAD_CACHE",
    BATCH_UPDATE = "BATCH_UPDATE",
    FEEDBACK = "FEEDBACK",
    EXPORT_ISSUES = "EXPORT_ISSUES",
    NEW_ATTRIBUTE = "NEW_ATTRIBUTE",
    UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE",
    ATTRIBUTE_MAPPING = "ATTRIBUTE_MAPPING",
    UPDATE_MODELING_SHOWCASE_ITEM = "UPDATE_MODELING_SHOWCASE_ITEM",
    WIP = "WIP", // Work In Progress
}

const DialogFactory: React.FC = () => {
    const location = useLocation();
    const { createDialog } = useContext(DialogContext);

    // translations
    const intl = useIntl();
    const transFeedbackTitle = intl.formatMessage({ ...feedbackMessages.title });
    const transWipTitle = intl.formatMessage({ ...wipMessages.title });

    useEffect(() => {
        createDialog({
            id: DF.WIP,
            title: transWipTitle,
            maxWidth: "sm",
            fullWidth: true,
            dialogContent: <WIP />,
        });
    }, []);

    useEffect(() => {
        createDialog({
            id: DF.FEEDBACK,
            title: transFeedbackTitle,
            maxWidth: "sm",
            fullWidth: true,
            dialogContent: <Feedback location={location} />,
        });
    }, [location]);

    return <></>;
};

export default DialogFactory;
