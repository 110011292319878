import moment from "moment";
import { round } from "lodash";

/**
 * Format date from UTC to DE locale
 * @param dateUTC
 */
export const formatDateFromUTC = (dateUTC: string): string => {
    moment.locale("de");
    return moment(new Date(dateUTC)).format("D. MMM YYYY HH:mm:ss");
};

/**
 * Check if a date is after another date
 * @param firstDate
 * @param secondDate
 */
export const dateIsAfter = (firstDate: string, secondDate: string): boolean => moment(firstDate).isAfter(secondDate);

/**
 * FormatDate
 * @param date
 */
export const formatDate = (date: Date): string => moment(date).format("D. M. YYYY");

/**
 * FormatDate
 * @param date
 */
export const formatDateSimple = (date: Date): string => moment(date).format("D. M.");

/**
 * FormatDatetime
 * @param date
 */
export const formatDateTime = (date: Date): string => moment(date).format("D. M. YYYY HH:mm:ss");

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export const formatNumber = (value: number|string) => {
    let numValue: number;
    if (typeof value === "number") {
        numValue = value;
    }
    else {
        numValue = parseFloat(value)
    }
    const rounded = round(numValue, 2);

    return rounded;
}
export const formatNumberThousands = (x: number, separator: string = " ") => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}