import React, {useCallback, useEffect, useState} from "react";
import {
    DataGrid,
    GridColDef,
    GridDensity,
    GridRowIdGetter,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    GridValidRowModel,
    csCZ
} from "@mui/x-data-grid";
import {Box, Button, Grid, Icon, IconButton, Typography} from "@mui/material";
import {useHistory, useRouteMatch} from "react-router";
import {Link} from "react-router-dom";
import {ItemField} from "../../CrudModule";
import useStyles from "./styles";
import {getDetailPath, getNewPath} from "../../pathUtils";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {Fab, Tooltip} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {Path} from "../../../../pages/PageRouter";
import {GridInitialStateCommunity} from "@mui/x-data-grid/models/gridStateCommunity";

export enum SelectMode {
    NONE,
    SINGLE,
    MULTIPLE
}

export interface CrudListConfiguration<LT extends GridValidRowModel> {
    title: string,
    disableAdd?: boolean,
    disableEdit?: boolean,
    disableRemove?: boolean,
    hideHeader?: boolean,
    getRowID?: GridRowIdGetter<LT>,
    selectMode?: SelectMode,
    density?: GridDensity,
    onSelectionChange?: (selection: any[]) => void,
    selection?: any[],
    initialState?: GridInitialStateCommunity,
    actionsHidden?: boolean,
}

export interface ICrudListProps<LT extends GridValidRowModel> {
    itemFields: ItemField<any>[],
    data: (() => Promise<LT[]>) | LT[],
    onDelete?: (id: number) => Promise<void>,
    config: CrudListConfiguration<LT>,
}


const getColumnDefinitions = (url: any, fields: ItemField<any>[], onDelete?: (id: number) => Promise<void>, disableEdit?: boolean, disableRemove?: boolean, actionsHidden?: boolean): GridColDef[] => {
    // let { url } = useRouteMatch();
    let columns: GridColDef[] = fields.filter(f => !f.columnDef?.ignore).map((f) => {
        let colDef =  {
            field: f.name,
            headerName: f.title,
        }
        return f.columnDef ? {
            ...f.columnDef,
            ...colDef
        } : colDef;
    });

    const handleClick = (params) => {
        if (onDelete) {
            onDelete(params["id"]);
        }
    }

    const hideActionColumn = () => {
        return disableEdit && disableRemove;
    }

    return hideActionColumn() ? columns : [
        ...columns,
        {
            field: 'actions',
            headerName: 'Akce',
            hideable: true,
            hide: !!actionsHidden,
            flex: 0,
            renderCell: (params) => (
                <>
                    {!disableEdit && (
                        <IconButton component={Link} to={`${getDetailPath(url, params.id)}`} size={"small"}>
                            <Icon fontSize={"small"}>edit</Icon>
                        </IconButton>
                    )}
                    {
                        !disableRemove && (
                            <IconButton onClick={() => handleClick(params)} size={"small"}>
                                <Icon fontSize={"small"}>close</Icon>
                            </IconButton>
                        )
                    }
                </>
            )
        }
    ];
}



const CrudList = <LT extends GridValidRowModel,>({config, itemFields, data, onDelete}: ICrudListProps<LT>) => {
    const classes = useStyles();
    let { url } = useRouteMatch();

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ rows, setRows ] = useState<LT[]>([]);
    const [ stateCount, setStateCount ] = useState<number>(0);

    const history = useHistory();

    const [ rowToDelete, setRowToDelete ] = useState<number>();

    const confirmOpen = Boolean(rowToDelete);

    useEffect(() => {
        if (typeof data == "function") {
            (data as (() => Promise<LT[]>))().then((response) => {
                setRows(response);
                setLoading(false);
            })
        }
        else {
            setRows(data);
            setLoading(false);
        }

    }, [data, stateCount]);

    const handleConfirmOpen = (id: number): Promise<void> => {
        setRowToDelete(id);
        return Promise.resolve();
    };

    const handleConfirmClose = () => {
        setRowToDelete(undefined);
    };

    const handleDelete = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            if (onDelete && rowToDelete) {
                onDelete(rowToDelete).then(res => {
                    handleConfirmClose();
                    setStateCount(stateCount+1);
                    resolve();
                });
            }
            else {
                resolve();
            }
        });

    },[stateCount, rowToDelete]);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <Grid container spacing={3} justifyContent={"space-between"}>
                    <Grid item>
                        <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        <GridToolbarFilterButton  nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        {/*<GridToolbarDensitySelector  nonce={undefined} onResize={undefined} onResizeCapture={undefined} />*/}
                        <GridToolbarExport />
                        {!config.disableAdd && (
                            <Button color={"primary"} variant={"text"} component={Link} to={`${getNewPath(url)}`} size={"small"}>
                                <Icon fontSize={"small"}>add</Icon>
                                Přidat nový
                            </Button>
                        )}
                    </Grid>
                    <Grid item className={classes.quickFilter}>
                        <GridToolbarQuickFilter  />
                    </Grid>
                </Grid>
            </GridToolbarContainer>
        );
    }




    return (
        <Box className={classes.listRoot}>
            { !config?.hideHeader && (
                <Grid className={classes.header} container spacing={2}>
                    <Grid item xs={8}>
                        <Box className={classes.gridItemContent}>
                            <Typography>{config.title}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}

            {/*<Paper style={{margin: "30px"}}>*/}
                <DataGrid
                    getRowId={config?.getRowID}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    loading={loading}
                    rows={rows}
                    density={config?.density ?? "standard"}
                    columns={getColumnDefinitions(url, itemFields, handleConfirmOpen, config.disableEdit, config.disableRemove, config.actionsHidden)}
                    components={{ Toolbar: CustomToolbar }}
                    keepNonExistentRowsSelected
                    onRowClick={(params, event) => {
                        // @ts-ignore
                        const cell = event.target.closest(".MuiDataGrid-cell");
                        if (!config.disableEdit && cell.dataset.field != 'actions') {
                            history.push(getDetailPath(url, params.id));
                        }

                    }}
                    localeText={{
                        ...csCZ.components.MuiDataGrid.defaultProps.localeText,
                        toolbarQuickFilterPlaceholder: "Hledej"
                    }}
                    onSelectionModelChange={values => {
                        if (config.onSelectionChange) {
                            config.onSelectionChange(values);
                        }
                    }}
                    initialState={config?.initialState}
                    selectionModel={config?.selection}
                    checkboxSelection={config.selectMode == SelectMode.SINGLE || config.selectMode == SelectMode.MULTIPLE}
                    disableSelectionOnClick
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            {/*</Paper>*/}


            <ConfirmDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleConfirm={handleDelete}
                messages={{
                    dialogTitle: "Potvrzení smazání", // "Delete confirm"
                    confirmMessage: "Chcete opravdu smazat záznam?",
                    confirmButton: "Smazat",
                    closeButton: "Zavřít",
                }}
            />

            <Box className={classes.fabOuter}>
                <Box className={classes.fabInner}>
                    {!config.disableAdd && (
                        <Tooltip title={"Přidat záznam"}>
                            <Fab color="primary" aria-label="add" component={Link} to={`${getNewPath(url)}`}>
                                <Add />
                            </Fab>
                        </Tooltip>
                    )}
                </Box>
            </Box>


        </Box>
    );
};
export default CrudList;