import { createContext, Dispatch, SetStateAction, useContext }  from 'react';

export interface ILanguageProviderContext {
    languages: string[];
    setLanguages: Dispatch<SetStateAction<string[]>>;
    currentLanguage: string|null;
    setCurrentLanguage: Dispatch<SetStateAction<string>>;
}

export const LanguageProviderContext = createContext<ILanguageProviderContext>({
    languages: [],
    setLanguages: () => {},
    currentLanguage: null,
    setCurrentLanguage: () => {}
});

export const useLanguageProvider = () => {
    const { languages, setLanguages, currentLanguage, setCurrentLanguage } = useContext(
        LanguageProviderContext
    );

    return {
        languages,
        setLanguages,
        currentLanguage,
        setCurrentLanguage
    };
};
