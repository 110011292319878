import React from "react";
import useStyles from "./styles";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import clsx from "clsx";

export interface IToolButtonProps {
    link: string,
    title: string
}

const ToolButton: React.FC<IToolButtonProps> = ({link, title}) => {
    const classes = useStyles();
    const location  = useLocation();
    return <Button
        component={Link}
        className={clsx(classes.btn, {["active"]: location.pathname == link})}
        to={link}
    >
        {title}
    </Button>
}

export default ToolButton;