import { ReactNode, useState } from "react";
import { DF } from "../../components/DialogFactory/DialogFactory";

export interface IDialogConfig {
    // settings
    id: DF;
    open?: boolean;
    maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
    fullWidth: boolean;

    // header
    title?: string;

    // content + action buttons
    dialogContent?: ReactNode;
}

const useDialog = () => {
    const [dialogs, setDialogs] = useState<IDialogConfig[]>([]);

    const toggleDialog = (id: DF, open?: boolean) => {
        setDialogs((prevState) => {
            return prevState?.map((dialog) =>
                dialog?.id === id ? { ...dialog, open: open === undefined ? !dialog?.open : open } : dialog
            );
        });
    };

    const createDialog = (newDialog: IDialogConfig) => {
        newDialog.open = false;
        setDialogs((prevState) => {
            return [...prevState?.filter((dialog) => dialog?.id !== newDialog?.id), newDialog];
        });
    };

    return {
        dialogs,
        createDialog,
        toggleDialog,
    };
};

export default useDialog;
