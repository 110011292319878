import React, {useCallback, useState} from "react";
import {OrderFileResponse} from "../../../../Api/data/order/types";
import {useKeycloak} from "@react-keycloak/web";
import {deleteOrderAttachment, getAllOrderAttachments} from "../../../../Api/data/order/client";
import CrudList, {
    CrudListConfiguration,
    ICrudListProps,
    SelectMode
} from "../../../../Crudder/components/CrudList/CrudList";
import {dateFormatter, dateTimeFormatter, fileSizeFormatter} from "../../../../Crudder/utils/utils";
import {downloadFile} from "../../../../Api/data/file/client";
import {Link} from "@material-ui/core";
import Button from "@mui/material/Button";



interface IOrderFileListProps {
    orderID: number;
    crudListProps?: Partial<CrudListConfiguration<OrderFileResponse>>
}

const OrderFileList: React.FC<IOrderFileListProps> = ({orderID, crudListProps}) => {

    const [ data, setData ] = useState<OrderFileResponse[]>([]);


    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const fetchData = useCallback(() => {
        return new Promise<OrderFileResponse[]>((resolve, reject) => {
            getAllOrderAttachments(orderID).then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteOrderAttachment(orderID, id).then((response) => {
                resolve();
            })
        });
    }, []);
    //
    // const getDetail = useCallback((id) => {
    //     return new Promise<any>((resolve, reject) => {
    //         resolve(data.find(i => i.orderID == id));
    //     });
    // }, [data]);


    return (
            <CrudList<OrderFileResponse>
                config={{
                    title: "Seznam souborů",
                    getRowID: (file) => file.orderFileID,
                    disableAdd: true,
                    hideHeader: true,
                    disableEdit: crudListProps?.disableEdit || crudListProps?.selectMode != SelectMode.NONE,
                    disableRemove: crudListProps?.disableRemove || crudListProps?.selectMode != SelectMode.NONE,
                    selectMode: crudListProps?.selectMode,
                    ...crudListProps
                }}
                itemFields={[
                    {
                        name: "name",
                        title: "Název",
                        columnDef: {
                            flex: 4,
                            renderCell: params => {
                                // params.row.
                                return (
                                    <Link component={Button} color={"inherit"} onClick={() => {
                                        downloadFile(params.row.fileID);
                                    }}>
                                        {params.value}
                                    </Link>
                                )
                            }
                        },
                    },
                    {
                        name: "createdDate",
                        title: "Vytvořeno",
                        columnDef: {
                            flex: 1,
                            valueFormatter: dateTimeFormatter
                        },
                    },
                    {
                        name: "size",
                        title: "Velikost",
                        columnDef: {
                            flex: 1,
                            valueFormatter: fileSizeFormatter
                        },
                    },
                    {
                        name: "mimeType",
                        title: "Typ",
                        columnDef: {
                            hide: true,
                            flex: 2
                        },
                    },
                ]}
                data={fetchData}
                onDelete={handleDelete}
            />
    );
};

export default OrderFileList;