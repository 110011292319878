import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContent: {
            [theme.breakpoints.up("md")]: {
                width: "80%",
                margin: "auto",
            },
        },
        textField: {
            "& input": {
                padding: "14px !important",
                fontSize: theme.typography.pxToRem(11),
            },
            "& textarea": {
                fontSize: theme.typography.pxToRem(11),
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.text.primary,
            },
        },
        select: {
            width: "11em",
            margin: "auto",
            minWidth: "auto",
            fontSize: theme.typography.pxToRem(11),
            "& .MuiTypography-root": {
                fontSize: theme.typography.pxToRem(11),
            },
        },
        selectMenuItem: {
            "& .MuiTypography-root": {
                fontSize: theme.typography.pxToRem(11),
            },
        },
        placeholder: {
            "& .MuiOutlinedInput-root, & .MuiSelect-root": {
                color: theme.palette.text.disabled,
            },
        },
        button: {
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(0.5, 2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(0.8, 3),
            },
        },
    })
);

export default useStyles;
