import { useContext } from "react";
import { DialogContext, IDialogContext } from "./GlobalDialogProvider";

const useGlobalDialog = () => {
    const { globalDialogOpen, setGlobalDialogOpen, setGlobalDialogTitle, setGlobalDialogContent } = useContext<
        IDialogContext
    >(DialogContext);

    return {
        globalDialogOpen,
        setGlobalDialogOpen,
        setGlobalDialogTitle,
        setGlobalDialogContent,
    };
};

export default useGlobalDialog;
