import {useGlobalDialog} from "@iolabs/wip-dialog";
import {Button, useTheme,} from "@material-ui/core";
import React from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import useStyles from "./styles";
import {useRouteMatch} from "react-router";

interface IOrdersMenuProps extends React.HTMLAttributes<HTMLElement> {
    basePath: string;
}

interface IOrdersMenuButtonProps extends React.HTMLAttributes<HTMLElement> {
    link?: string;
}

const OrdersMenuButton: React.FC<IOrdersMenuButtonProps> = ({link, children}: IOrdersMenuButtonProps) => {
    return (
        <Link to={`${link}`}>
            <Button color={"inherit"}>{children}</Button>
        </Link>
    );
}

const OrdersMenu: React.FC<IOrdersMenuProps> = ({basePath}: IOrdersMenuProps) => {

    const classes = useStyles();


    return (
        <>
            <div className={classes.menu}>
                <OrdersMenuButton link={`${basePath}/orders`}>Nabídky</OrdersMenuButton>
                <OrdersMenuButton link={`${basePath}/companies`}>Firmy</OrdersMenuButton>
                {/*<OrdersMenuButton link={`${basePath}/currencies`}>Měny</OrdersMenuButton>*/}
                {/*<OrdersMenuButton link={`${basePath}/files`}>Soubory</OrdersMenuButton>*/}
                {/*<OrdersMenuButton link={`${basePath}/system-variables`}>Proměnné systému</OrdersMenuButton>*/}
            </div>
        </>
    );
};

export default OrdersMenu;
