import React from "react";

interface ICounterProps {
    name: string
}

const Counter: React.FC<ICounterProps> = ({name}) => {
    console.log("XXX Counter", name)
    return null;
}
export default Counter;