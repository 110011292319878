import { Box, Link, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import authorLogo from "../../assets/images/logo-iolabs-black.svg";
import config from "../../config/config";
import useFooterContent from "../FooterProvider/useFooterContent";
import messages from "./messages";
import useStyles from "./styles";

export type StylesProps = {
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;
};

interface IFooterProps {
    stylesProps?: StylesProps;
    hideLasUpdate?: boolean;
}

const Footer: React.FC<IFooterProps> = (props: IFooterProps) => {
    const { stylesProps, hideLasUpdate } = props;

    const classes = useStyles(stylesProps);
    const { footerContent } = useFooterContent();

    const getYear = () => {
        return new Date().getFullYear();
    };

    // translations
    const intl = useIntl();
    const transWebsite = intl.formatMessage({ ...messages.website });
    const transUpdate = intl.formatMessage({ ...messages.update });
    const transCopyright = intl.formatMessage(
        { ...messages.copyright },
        {
            year: getYear(),
        }
    );

    // const dateLastUpdate = config.build.lastUpdate ? new Date(config.build.lastUpdate) : new Date();

    return (
        <Toolbar className={classes.root}>
            <Box
                display="flex"
                justifyContent="center"
                pl={1}
                pr={1}
                className={clsx({ [classes.inline]: hideLasUpdate }, classes.content)}
            >
                <Typography component="p" className={clsx(classes.paragraph, classes.copyIolabs, "copyright")}>
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit">
                        {transCopyright}
                    </Link>
                    {` | `}
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit">
                        {transWebsite}
                    </Link>
                    {` | `}
                    <Link href={config.content.authorWebUrl} target="_blank" color="inherit" className={classes.logo}>
                        <img alt="Logo" src={authorLogo} />
                    </Link>
                </Typography>
                {!hideLasUpdate && (
                    <Typography component="p" className={clsx(classes.paragraph, classes.copyUpdate)}>
                        {footerContent ? `${transUpdate} ${footerContent}` : ""}
                        {/*
                    {config.build.lastUpdate && (
                        <>
                            {transUpdate} {` `}
                            <Tooltip title={config.build.lastVersion} placement="top">
                                <Typography
                                    component="span"
                                    variant="inherit"
                                    className={clsx(classes.paragraph, classes.lastUpdate)}
                                >
                                    {`${dateLastUpdate.toLocaleDateString("de-DE")} ${dateLastUpdate.toLocaleTimeString(
                                        "de-DE"
                                    )}`}
                                </Typography>
                            </Tooltip>
                        </>
                    )}
                    */}
                    </Typography>
                )}
            </Box>
        </Toolbar>
    );
};

export default Footer;
