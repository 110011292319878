import {generatePath} from "react-router";
import {AppPage} from "../../pages/PageRouter";
import {useCallback} from "react";

export const getDetailPath = (basePath: string, id: string|number) => {
    return `${basePath}/${id}`;
}

export const getNewPath = (basePath: string) => {
    return `${basePath}/new`;
}

export const useGetPath = () => {
    // const activeProject: ProjectData | undefined = useActiveProject();

    const getPath = (path: string, params: {[key: string]: string|undefined} = {}): string => {
        try {
            return generatePath(path, {
                ...params
            });
        }
        catch (e) {
            return "#";
        }
    };

    const getPagePath = (page: AppPage): string => {
        return getPath(page.path)
    };
    //
    // const getProjectCode =  useCallback(() : string => {
    //     return activeProject?.code ? activeProject.code : "__UNINITIALIZED__";
    // },[activeProject]);



    return { getPath, getPagePath };
}
