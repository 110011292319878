import React from "react";
import {ItemField} from "../../../../Crudder/CrudModule";

import { FieldArray } from "react-final-form-arrays";
import StyledTextField from "../../../../Crudder/components/CrudDetail/StyledTextField";
import {FormApi} from "final-form";
import {Grid, Button, Box, Avatar} from "@material-ui/core";
import useStyles from "./styles";
import {useTheme} from "@material-ui/core/styles";

interface IOrderItemsFormPartProps {
    field: ItemField<any>,
    readonly?: boolean,
    form: FormApi
}

const CompanyContactsFormPart: React.FC<IOrderItemsFormPartProps> = ({ field, readonly , form}) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <>
            <FieldArray name="contactPersons">
                {({ fields }) =>
                    fields.map((name, index) => (
                        <Box className={classes.itemRoot} key={`item-${name}`}>
                            <Grid className={classes.itemForm} container spacing={3} key={name}>
                                <Grid item xs={2}>
                                    <StyledTextField
                                        name={`${name}.email`}
                                        label={"Email"}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <StyledTextField
                                        name={`${name}.titlesBefore`}
                                        label={"Tituly před"}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <StyledTextField
                                        name={`${name}.firstName`}
                                        label={"Jméno"}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <StyledTextField
                                        name={`${name}.lastName`}
                                        label={"Příjmení"}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <StyledTextField
                                        name={`${name}.titlesAfter`}
                                        label={"Tituly za"}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <StyledTextField
                                        name={`${name}.telephone`}
                                        label={"Telefon"}
                                    />
                                </Grid>
                            </Grid>

                            <Box className={classes.itemActions}>
                                <span
                                    onClick={() => fields.remove(index)}
                                    style={{ cursor: "pointer" }}
                                >❌</span>
                            </Box>
                        </Box>

                    ))
                }
            </FieldArray>
            <div className="buttons">
                <Button
                    type="button"
                    color={"primary"}
                    onClick={() => form.mutators.push("contactPersons", undefined)}
                >
                    Přidat
                </Button>
                <Button type="button"
                        color={"primary"}
                        onClick={() => form.mutators.pop("contactPersons")}
                >
                    Odebrat
                </Button>
            </div>
        </>
    );
}
export default CompanyContactsFormPart;