import React, { ReactElement, useEffect, useState } from "react";
import { IRouteParams, ItemField } from "../../CrudModule";
import CrudDetailForm, {IFormTemplate} from "./CrudDetailForm";
import CrudDetailTabs from "./CrudDetailTabs";
import { useParams } from "react-router";
import { Alert, Skeleton } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import {Decorator, Mutator} from "final-form";
import {useCrudderContext} from "../../CrudderContext";

export interface CrudDetailTabConfiguration<DT> {
    title: string;
    component: React.FC<ICrudDetailLoadedProps<DT>>;
    slug: string;
}

export interface CrudDetailConfiguration<DT> {
    title: string,
    component?: React.FC<ICrudDetailProps<DT>>,
    componentNew?: React.FC<ICrudDetailProps<DT>>|((props:ICrudDetailProps<DT>) => ReactElement) ,
    tabs?: CrudDetailTabConfiguration<DT>[],
    // formTemplate?: (props: IFormTemplate<DT>) => ReactElement
    formTemplate?: React.FunctionComponent<Partial<ICrudDetailFormProps<DT>>>,
    decorators?: Array<Decorator<FormData>>,
    mutators?: { [key: string]: Mutator },
    showReset?: boolean,
}
export interface ICrudDetailProps<DT> extends ICrudDetailBaseProps<DT> {
    data?: ((id: number|string) => Promise<DT>) | DT,
    initData?: (() => Promise<DT>) | DT,
}

export interface ICrudDetailLoadedProps<DT> extends ICrudDetailBaseProps<DT> {
    data: DT,
}
export interface ICrudDetailFormProps<DT> extends ICrudDetailBaseProps<DT> {
    data?: DT,
}

export interface ICrudDetailBaseProps<DT> {
    config: CrudDetailConfiguration<DT>,
    itemFields: ItemField<DT>[],
    loading?: boolean,
    onSubmit: (values: FormData) => Promise<number>,
    backLink: string;
}


const CrudDetail = <DT,>( { config, itemFields, data, loading, onSubmit, backLink}: ICrudDetailProps<DT> ) => {

    let { itemID } = useParams<IRouteParams>();
    const [ dataLoading, setDataLoading ] = useState<boolean>(false);
    const [ detailData, setDetailData ] = useState<DT>();


    const { detailStateCounter } = useCrudderContext();

    useEffect(() => {
        if (data) {
            setDataLoading(true);
            (data as ((id: number|string) => Promise<DT>))(itemID).then((response) => {
                setDetailData(response);
                 setDataLoading(false);
            })
        }
    }, [data, detailStateCounter]);


    return (
        <>
            {
                dataLoading ? (
                    <CircularProgress />
                ) : (config.tabs && detailData) ? (
                    <CrudDetailTabs<DT> config={config} itemFields={itemFields} data={detailData} onSubmit={onSubmit} backLink={backLink} />
                ) : (
                    <CrudDetailForm<DT> config={config} itemFields={itemFields} data={detailData} onSubmit={onSubmit} backLink={backLink} />
                )
            }
        </>
    );
};
export default CrudDetail;