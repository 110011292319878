import { IModule } from "redux-dynamic-modules";
import { IAppModuleState, IAppState, reducer } from "./reducer";
import middlewares from "./middleware";

export const AppModule: IModule<IAppModuleState> = {
    id: "app-module",
    reducerMap: {
        appState: reducer,
    },
    middlewares: middlewares
};