import { Button, createStyles, Dialog, IconButton, Theme, withStyles } from "@material-ui/core";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        title: {
            fontSize: "1.8rem",
            lineHeight: "1.8rem",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[600],
        },
        globalDialog: {
            minWidth: "50vw",
        },
    })
);

interface IDialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const DialogTitle = (props: IDialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h4" component="h2" className={classes.title}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export interface IDialogContext {
    globalDialogOpen: boolean;
    setGlobalDialogOpen: Dispatch<SetStateAction<boolean>>;
    setGlobalDialogTitle: Dispatch<SetStateAction<string | null>>;
    setGlobalDialogContent: Dispatch<SetStateAction<string | null>>;
}

export const DialogContext = createContext<IDialogContext>({
    globalDialogOpen: false,
    setGlobalDialogOpen: () => {},
    setGlobalDialogTitle: () => {},
    setGlobalDialogContent: () => {},
});

interface IDialogProps {}

const GlobalDialogProvider: React.FC<PropsWithChildren<IDialogProps>> = (props: PropsWithChildren<IDialogProps>) => {
    const classes = useStyles();
    const { children } = props;

    const [globalDialogOpen, setGlobalDialogOpen] = useState<boolean>(false);
    const [globalDialogTitle, setGlobalDialogTitle] = useState<string | null>(null);
    const [globalDialogContent, setGlobalDialogContent] = useState<string | null>(null);

    const value = { globalDialogOpen, setGlobalDialogOpen, setGlobalDialogTitle, setGlobalDialogContent };

    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => {
        setGlobalDialogOpen(false);
        setGlobalDialogTitle(null);
        setGlobalDialogContent(null);
    };

    // translations
    const intl = useIntl();
    const transTitle = intl.formatMessage({ ...messages.title });
    const transContent = intl.formatMessage({ ...messages.content });
    const transButton = intl.formatMessage({ ...messages.button });

    return (
        <DialogContext.Provider value={value}>
            {children}

            {globalDialogOpen && (
                <Dialog
                    onClose={handleClose}
                    aria-labelledby="demo-dialog-title"
                    open={globalDialogOpen}
                    maxWidth="lg"
                    // fullScreen={fullScreen}
                    fullWidth
                    scroll="paper"
                    className={classes.globalDialog}
                >
                    <DialogTitle id="demo-dialog-title" onClose={handleClose}>
                        {globalDialogTitle ? globalDialogTitle : transTitle}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Typography paragraph gutterBottom>
                            {globalDialogContent ? globalDialogContent : transContent}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} variant="contained" color="primary">
                            {transButton}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </DialogContext.Provider>
    );
};

export default GlobalDialogProvider;
